import React, { useEffect } from "react";
import styles from "./CaseStudyPreview.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useDispatch, useSelector } from "react-redux";
import { listCaseStudy } from "../../store/actions/caseStudyActions";

const CaseStudyPreview = () => {
  const dispatch = useDispatch();

  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  const filtered =
    caseStudies && caseStudies.sort(() => Math.random()).slice(0, 10);

  useEffect(() => {
    dispatch(listCaseStudy());
  }, []);

  return (
    <div className={styles.main}>
      <Container className="text-center">
        <h4 className="h4_main">
          <span>Case Studies</span>
        </h4>
        <h1 className="h1_small">
          Preview From Our <span>Portfolio</span>
        </h1>
        <Row className={`${styles.rowCont}  gy-5`}>
          {loading ? (
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  color: "green",
                  width: "120px",
                  height: "120px",
                  alignSelf: "center",
                  textAlign: "center",
                }}
              />
            </span>
          ) : error ? (
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="para_main" style={{ color: "red" }}>
                {error}
              </p>
            </span>
          ) : (
            <Swiper
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                1200: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                778: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
              }}
            >
              {caseStudies.length!==0 && filtered.map((item, index) => (
                <SwiperSlide key={index} className={styles.swiperSlide}>
                  <Link style={{ textDecoration: "none" }} to={`/casestudy/${item._id}`}>
                    <div className={styles.box}>
                      <div className={styles.imgbackgroundCont}>
                        <img src={item.bannerImage} className={styles.imgBackground} />
                      </div>
                      <div className={styles.head}>
                        <h2 className="h2_main">{item.heroHeading}</h2>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Row>

        <div className={styles.readMore}>
          <Link style={{ textDecoration: "none" }} to="/case-studies">
            <div>
              <h2 className="h4_main">
                <span>Explore All</span>
              </h2>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
              </svg>
            </div>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default CaseStudyPreview;
