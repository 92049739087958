import React from "react";
import Hero from "../../Component/Hero/Hero";
import Reviews from "../../Component/Reviews/Reviews";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import InfoSec from "../../Component/InfoSec/InfoSec";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import { Helmet } from "react-helmet";

const SEOPage = () => {
  return (
    <>
      <Helmet>
        <title>Search Engine Optimization Service - TekCorp</title>
        <meta name="keywords" content="Search Engine Optimization Company in Pakistan" />
        <meta name="keywords" content="Search Engine Optimization Company in UAE" />
        <link rel="canonical" href="https://www.tekcorpltd.com/search-engine-optimization" />
        <meta
          name="description"
          content="Effective SEO allows you to produce large numbers of high-quality organic leads from your website while also transforming it from a basic online informative resource into an active sales channel. Our experts can effectively handle your seo and help generate leads within no time."
        />
      </Helmet>
      <Hero
        subHeading={"Enhance your search rankings and drive organic traffic"}
        heading={"Boost Your Online Visibility with TekCorp"}
      />
      <InfoSec
        data={{
          highlightedWord: "Result-Driven",
          mainHeading: "SEO Services",
          para: `Increase visibility using SEO services. With a well-executed SEO plan, we can help you outrank your top competitors, attract the proper audiences, and produce leads at a lower cost.`,
          subHeading1: "Deep Research",
          subPara1: `Our experts create a clear plan and do in-depth research in your niche that outlines how and what we will accomplish for you.`,
          subHeading2: "Proven Methodology",
          subPara2: `We believe in creating personalized plans that are tailored to our client's specific needs and circumstances.`,
          subHeading3: "Effective Strategies",
          subPara3: `We've been practicing our procedures and making a difference for our clients for almost a decade. We collaborate to develop unique goals that are in line with your business objectives. Our full-service SEO optimization team is here to help you achieve company success.`,
        }}
      />
      <ImgByContent
        img={"/images/seo1.jpg"}
        services={true}
        span={"Why Choose "}
        number={"01"}
        heading={"TekCorp for Search Engine Optimization ?"}
        text={
          "Leveraging years of SEO expertise, we provide innovative, results-driven services that consistently enhance search marketing strategies and campaign effectiveness. Our dedicated efforts boost traffic, sales, and business growth, all while strictly adhering to Google’s best practices and guidelines from other leading search engines."
        }
        text2={
          "Effective SEO allows you to produce large numbers of high-quality organic leads from your website while also transforming it from a basic online informative resource into an active sales channel. Our experts can effectively handle your seo and help generate leads within no time."
        }
      />
      <ImgByContent
        img={"/images/seo2.jpg"}
        reverse={true}
        services={true}
        span={"Delivering "}
        number={"02"}
        heading={"Digital Success to Our Clients"}
        text={
          "At TekCorp, our SEO specialists connect you with your target audience, driving your business to the forefront of search results. We develop a tailored SEO strategy that ensures your goals are met, positioning your business ahead of competitors and boosting your online visibility."
        }
        text2={
          "We offer more than just a website; we provide a comprehensive client base and an effective online sales system. Our clients recommend us for our unwavering commitment to quality, integrity, and a results-driven approach."
        }
      />
      <ImgByContent
        img={"/images/seo3.jpg"}
        services={true}
        span={"How does "}
        number={"03"}
        heading={"SEO affect my sales?"}
        text={
          "SEO maximizes your visibility to potential customers, turning interest into purchases. The key to driving sales is targeting users actively searching for the products or services you offer. Our SEO strategy focuses on incorporating the most effective keywords to boost conversion rates, sales, and return on investment (ROI)."
        }
        text2={
          "Our team of experts handles each step of the process with the utmost research, focus, technicality, and strategy with only one goal in mind and that is to create results. Using this approach our SEO specialists give their best and improve your position on the search engine pages (SERPs)."
        }
      />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default SEOPage;
