import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import AppDevSec from "../../Component/AppDevSec/AppDevSec";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const AppDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>App Development Service - TekCorp</title>
        <meta name="keywords" content="App Development Company in Pakistan" />
        <meta name="keywords" content="App Development Company in UAE" />
        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/app-development"
        />
        <meta
          name="description"
          content="Revolutionize your business by implementing cutting-edge applications that boost performance, create exceptional user experiences, and drive market leadership."
        />
      </Helmet>
      <Hero
        subHeading={"Crafting tailored experiences that drive growth"}
        heading={"Elevate Your Business with Engaging Mobile Apps"}
      />
      <ImgByContent
        heading={"Pioneering Innovative Apps and Services"}
        img={"/images/app1.png"}
        text={`Revolutionize your business by implementing cutting-edge applications that boost performance, create exceptional user experiences, and drive market leadership.`}
        appDev={true}
        reverse={true}
      />
      <AppDevSec />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default AppDevelopment;
