import React, { useEffect, useState } from "react";
import styles from "./TestimonialsSummary.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const TestimonialsSummary = ({
  casestudy,
  category1,
  category2,
  category3,
  category4,
  title1,
  industry,
  industryText,
  videoURL,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isReady]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setIsMuted(false);
      window.removeEventListener("click", handleUserInteraction);
    };

    window.addEventListener("click", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  return (
    <div className={styles.main}>
      <Container>
        {videoURL ? (
          <Row className="gy-2 gx-lg-5 mt-5 ">
            <Col lg={6}>
              {casestudy && (
                <div className={styles.contentCol}>
                  <h1 className="h1_main pb-4">
                    <span>{title1}</span>
                  </h1>
                  <h4 className="h4_main pb-3">
                    INDUSTRY: <span>{industry}</span>
                  </h4>
                  <p className="para_main pe-lg-5 pb-4">{industryText}</p>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <div className={styles.box}>
                <div className={styles.videoCont}>
                  <ReactPlayer
                    url={videoURL}
                    playing={isPlaying}
                    controls={true}
                    volume={1}
                    muted={isMuted}
                    width="100%"
                    height="100%"
                    onReady={() => setIsReady(true)}
                    onError={(e) => console.log("Error loading video:", e)}
                  />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="mt-5 text-center">
            {casestudy && (
              <div className={styles.contentCol}>
                <h2 className="h1_main text-center pb-4">
                  <span>{title1}</span>
                </h2>
                <h2 className="h4_main text-center pb-3">
                  INDUSTRY: <span>{industry}</span>
                </h2>
                <h2 className="para_main text-center pe-lg-5 pb-4">
                  {industryText}
                </h2>
              </div>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default TestimonialsSummary;
