import React from "react";
import styles from "./Partnerships.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { webData } from "./data";
import { maintenanceData } from "./maintenanceData";

const Partnerships = ({ web, maintenancePage }) => {
  return (
    <div
      style={{ background: (web || maintenancePage) && "#F4F4F5" }}
      className="main text-center"
    >
      <Container>
        {!web && !maintenancePage && (
          <>
            <h4 className="h4_main pb-2">Trusted Brands _</h4>
            <h1 className="h1_small">
              <span>Partnerships we</span> have built
            </h1>
          </>
        )}
        {maintenancePage && (
          <>
            <h4 className="h4_main pb-2">Secondary Services</h4>
            <h1 className="h1_small">
              <span>Tiered Support</span> Levels
            </h1>
          </>
        )}
        {web && (
          <>
            <h4 className="h4_main pb-2 text-start">
              <span>Engineering Success in Every Click_</span>
            </h4>
            <h1 className="h1_small text-start">
              <span>Transforming </span> Visions into
              <br /> <span>pixel-perfect </span> Reality
            </h1>
          </>
        )}
        {!web && !maintenancePage && (
          <Row className="pt-5 gy-3">
            <Col lg={3}>
              <div className={styles.box}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={`${styles.box} ${styles.white}`}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={styles.box}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={`${styles.box} ${styles.white}`}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={`${styles.box} ${styles.white}`}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={styles.box}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={`${styles.box} ${styles.white}`}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className={styles.box}>
                <img src="/images/yelp.svg" />
                <p className="pt-3 para_main">
                  Lorem ipsum dolor sit amet, consectr adipiscing elit, sed do
                  eius teor incididunt.
                </p>
              </div>
            </Col>
          </Row>
        )}
        {web && (
          <Row className="justify-content-center pt-5 gy-4">
            {webData.map((item, index) => (
              <Col key={index} xl={3} lg={4}>
                <div
                  style={{ background: "white", padding: "25px" }}
                  className={styles.box}
                >
                  <img
                    style={{ background: "#f6f6f6", padding: "10px" }}
                    src={item.img}
                  />
                  <h3 className="h4_main pt-3">{item.heading}</h3>
                  <p className="para_main pt-2">{item.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        )}

        {maintenancePage && (
          <Row className="pt-5 gy-3">
            {maintenanceData.map((item, index) => (
              <Col key={index} lg={4}>
                <div
                  style={{ background: "white", padding: "25px" }}
                  className={styles.box}
                >
                  <img
                    style={{ background: "#f6f6f6", padding: "15px" }}
                    src={item.img}
                  />
                  <h3 className="h2_main pt-3">{item.heading}</h3>
                  <p className="para_main pt-2">{item.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Partnerships;
