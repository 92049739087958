import React from "react";
import styles from "./Footer.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.main}>
      <footer className={styles.footer}>
        <Container>
          <Row className="gy-4 pb-lg-5 pb-3">
            <Col lg={9}>
              <div className={styles.contentCol}>
                <h3 className="h3_main_white_gradient">
                  Let's build or improve your digital product
                </h3>
                <h1 className="h1_main h1_white_gradient pb-lg-3">
                  No project is too big or
                  <br /> too small. Hit us up
                  <br />
                  and let's build.
                </h1>
                <a
                  style={{ textDecoration: "none" }}
                  href="tel: +92 335 7221733"
                >
                  <div className={styles.pill}>
                    <h4 className="h4_main ps-3">Schedule a Call</h4>
                    <img src="/images/icons/arrow.svg" />
                  </div>
                </a>
              </div>
            </Col>
            <Col lg={3}>
              <div className={styles.imgCol}>
                <img src="/images/footer_phone.svg" />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              borderTop: "1px solid rgba(49, 50, 51, 1)",
              borderBottom: "1px solid rgba(49, 50, 51, 1)",
            }}
            className="pt-4 pb-4 gx-lg-0 "
          >
            <Col lg={3}>
              <div className={styles.linkCol}>
                <p className="para_main">Connect</p>
                <a href="mailto:support@tekcorpltd.com">
                  <p className="para_main">support@tekcorpltd.com</p>
                </a>
                <a href="tel:+92 335 7221733">
                  <p className="para_main">+92 335 7221733</p>
                </a>
              </div>
            </Col>
            <Col lg={2}>
              <div className={styles.linkCol}>
                <p className="para_main">Quick Links</p>
                <Link to="/company">
                  <p className="para_main">About us</p>
                </Link>
                <Link to="/case-studies">
                  <p className="para_main">Portfolio</p>
                </Link>
                <Link to="/contact">
                  <p className="para_main">Contact</p>
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.linkCol}>
                <p className="para_main">Legal</p>
                <Link to="/refund-policy">
                  <p className="para_main">Refund Policy</p>
                </Link>
                <Link to="/privacy-policy">
                  <p className="para_main">Privacy policy</p>
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={styles.socialCol}>
                <div>
                  <p className="para_main pb-3">Follow</p>
                  <div className={styles.socials}>
                    <a
                      target="no_blank"
                      href="https://www.linkedin.com/company/tekcorpltd/"
                    >
                      <div className={styles.social}>
                        <img
                          alt="LinkedIn"
                          src="/images/icons/linkedin_contact.svg"
                        />
                      </div>
                    </a>
                    <a
                      target="no_blank"
                      href="https://www.facebook.com/profile.php?id=61563448207086"
                    >
                      <div className={styles.social}>
                        <img
                          alt="Facebook"
                          src="/images/icons/facebook_contact.svg"
                        />
                      </div>
                    </a>
                    <a
                      target="no_blank"
                      href="https://www.instagram.com/tekcorpltd/"
                    >
                      <div className={styles.social}>
                        <img
                          alt="Instagram"
                          src="/images/icons/instagram.svg"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className={styles.bottomRow}>
        <Container>
          <div className={styles.bottomRowInner}>
            <p className="para_main">
              © Copyright 2024 Tekcorp Limited - All Rights Reserved
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Footer;
