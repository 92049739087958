import React from "react";
import styles from "./HeadingContent.module.css";
import { Col, Container, Row } from "react-bootstrap";

const HeadingContent = ({
  darkBackground,
  img,
  heading,
  subHeading,
  para,
  paddingBottom,
  paddingTop,
}) => {
  return (
    <div
      style={{
        backgroundImage:
          darkBackground &&
          "linear-gradient(to right, #1b1b1b , rgb(69, 69, 69))",
        paddingBottom: paddingBottom,
        paddingTop: paddingTop,
      }}
      className={styles.main}
    >
      <Container>
        <Row className="gy-5">
          <Col lg={6}>
            <div className={styles.headingCol}>
              <h4
                style={{ color: darkBackground && "white" }}
                className="h4_main"
              >
                {subHeading}
              </h4>
              <h1 className={darkBackground ? 'heading_capital h1_white_gradient': 'heading_capital'}>
                <span>{heading}</span>
              </h1>
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.paraCol}>
              <p
                style={{ color: darkBackground && "white" }}
                className="para_main"
              >
                {para}
              </p>
            </div>
          </Col>
          {img && (
            <Col lg={12}>
              <div className={styles.imgCont}>
                <img className="img-fluid" src={img} alt="img"/>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HeadingContent;
