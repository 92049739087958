import React from "react";
import styles from "./AboutSec.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AboutSec = () => {
  return (
    <div className="main">
      <Container>
        <Row className="gy-4">
          <Col lg={8}>
            <div className={styles.contentCol}>
              <h3 className="h3_main pb-2">— About TekCorp</h3>
              <h1 className="h1_small pb-4">
                <span>We deliver comprehensive, efficient,</span> and scalable
                <span> solutions tailored for your business needs.</span>
              </h1>
              <p className="para_main pb-4 pe-5">
                TekCorp is dedicated to helping forward-thinking businesses
                achieve their goals through strategic and innovative approach.
                In a constantly evolving world, staying ahead of the competition
                requires leaving no stone unturned. Our dynamic team of experts
                is committed to continuous innovation, ensuring that your
                business gains a competitive edge and thrives in today’s
                fast-paced market.
              </p>
              <img alt="" src="/images/aboutsec.jpg" />
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.boxCont}>
              <div className={styles.box}>
                <div>
                  <h3 className="h3_main pb-2">Our Ambition</h3>
                  <p className="para_main">
                    We strive to be the catalyst that propels businesses to
                    unprecedented heights. We are committed to pushing
                    boundaries and setting new standards in success.
                  </p>
                </div>
                <Link style={{ textDecoration: "none" }} to={"/contact"}>
                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Learn More</h4>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3_9)">
                        <path
                          d="M4.66668 11.3333L11.3333 4.66666"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.33334 4.66667H11.3333V10.6667"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3_9">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="matrix(-1 0 0 -1 16 16)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
              <div className={styles.box}>
                <div>
                  <h3 className="h3_main pb-2">Our Mission</h3>
                  <p className="para_main">
                    Our mission is to empower businesses to achieve their
                    fullest potential through innovative, data-driven digital
                    marketing and software solutions. We are dedicated to
                    delivering exceptional results and driving meaningful growth
                    for our clients.
                  </p>
                </div>
                <Link style={{ textDecoration: "none" }} to={"/contact"}>
                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Learn More</h4>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3_9)">
                        <path
                          d="M4.66668 11.3333L11.3333 4.66666"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.33334 4.66667H11.3333V10.6667"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3_9">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="matrix(-1 0 0 -1 16 16)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutSec;
