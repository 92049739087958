import React from "react";
import styles from "./Specialization.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Specialization = ({ data }) => {
  return (
    <div className="main">
      <Container>
        {data && (
          <div className="text-center">
            <h4 className="h4_main pb-2">Crafting Professional Experience _</h4>
            <h1 className="h1_small pb-4">
              Design <span> that fits </span> your <br /> audience's needs
            </h1>
          </div>
        )}
        {!data && (
          <Row className="gy-4 gx-lg-5 pb-4">
            <Col lg={8}>
              <div
                style={{ width: "100%", height: "100%" }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <h2
                  style={{ alignSelf: "start" }}
                  className="h2_main text-start pb-2"
                >
                  <span>Specialization_</span>
                </h2>
                <h1
                  style={{ alignSelf: "start" }}
                  className="h1_main text-start"
                >
                  <span>Unified Solutions For</span>
                  <br />
                  <span>Your Business.</span>
                </h1>
              </div>
            </Col>
            <Col lg={4}>
              <div
                style={{ width: "100%", height: "100%" }}
                className="d-flex justify-content-center align-items-center"
              >
                <p className="para_main text-start">
                  We are a 360 software solutions company aiming to ensure your
                  firm's growth. We provide cutting-edge, worldwide competence,
                  and affordable client service.
                </p>
              </div>
            </Col>
          </Row>
        )}
        {!data && (
          <Row className="gy-0 gx-0 pt-3">
            <Col
              style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
              lg={6}
              xl={4}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                <div className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">Web Engineering</h2>
                    <p className="para_main">
                      Well-structured sites are a necessity in the digital era
                      to grow. Our web engineering services are specifically
                      aimed at developing highly seo-effective, user-friendly,
                      and robust websites that fulfill the requirements unique
                      to your business.
                    </p>
                  </div>
                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Get Started</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
              lg={6}
              xl={4}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                <div className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">Application Engineering</h2>
                    <p className="para_main">
                      In the rapidly evolving technology world, dependable and
                      fast applications are the key to success for the business.
                      Our application engineering services are committed to
                      designing, developing, and deploying top-tier applications
                      tailored to your unique needs.
                    </p>
                  </div>
                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Get Started</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
              lg={6}
              xl={4}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                <div className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">Prototyping & Design</h2>
                    <p className="para_main">
                      Customized software and thorough prototyping can set you
                      apart from your competition. Our Prototyping & Software
                      design services emphasize transforming your concepts into
                      practical interfaces and prototypes that form a solid
                      basis for further software development.
                    </p>
                  </div>
                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Get Started</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
              lg={6}
              xl={4}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                <div className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">QA & Testing</h2>
                    <p className="para_main">
                      High quality and security in a software is the key element
                      of providing customers with satisfaction. Our dedicated
                      services are structured to provide you with all-inclusive
                      and trained solutions that supports you to ensure your
                      software is soundly protected and works perfectly.
                    </p>
                  </div>

                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Get Started</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
              lg={6}
              xl={4}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                <div className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">Maintenance & Support</h2>
                    <p className="para_main">
                      We offer a complete set of tools to ensure the optimal
                      performance of your applications so you can deploy your
                      effort in the more important areas of your business
                      operations.
                    </p>
                  </div>

                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Get Started</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
              lg={6}
              xl={4}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                <div className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">Digital Marketing</h2>
                    <p className="para_main">
                      We offer a wide range of digital marketing solutions that
                      are meant to elevate the visibility of your brand, capture
                      the attention of your target audience, and make a
                      measurable impact. We create effective advertisement
                      campaigns with our team of professionals at TekCorp.
                    </p>
                  </div>

                  <div className={styles.footer}>
                    <h4 className="h4_main ps-3">Get Started</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        )}
        {data && (
          <Row className="gy-0 gx-0 pt-3">
            {data.map((item, index) => (
              <Col
                key={index}
                style={{ border: "1px solid rgba(23, 25, 26, 1)" }}
                lg={6}
                xl={4}
              >
                <div style={{ minHeight: "420px" }} className={styles.box}>
                  <div className={styles.content}>
                    <img src="/images/icons/web_engineering.svg" />
                    <h2 className="h2_main pt-2">{item.heading}</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.text }}
                      className="para_main"
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
};
export default Specialization;
