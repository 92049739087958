import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./ContactPageForm.module.css";

const ContactPageForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form", {
        name,
        email,
        phoneNum,
        businessName,
        role,
        message,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Form Submitted Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <div className="main ">
      <Container className="pt-5 pe-5">
        <h4 className="h4_main pb-2">Contact us _</h4>
        <h1 className="h1_small pb-5">
          <span>Let's talk about</span> the Details
        </h1>
        <div className={styles.formCol}>
          {loading ? (
            <div className="row pb-4">
              <div className="col-12">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "black" }}
                />
              </div>
            </div>
          ) : success ? (
            <div className="row pb-4">
              <div className="col-12">
                <p className="para_main" style={{ color: "black" }}>
                  {success}
                </p>
              </div>
            </div>
          ) : (
            error && (
              <div className="row pb-4">
                <div className="col-12">
                  <p className="para_main" style={{ color: "black" }}>
                    {error}
                  </p>
                </div>
              </div>
            )
          )}
          <form onSubmit={formSubmitHandler}>
            <input
              maxLength="12"
              type="text"
              placeholder="Name"
              pattern=".{3,}"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <Row lg={12} className="gy-4 w-100 p-0">
              <Col lg={6} className="ps-0">
                <input
                  maxLength="36"
                  type="email"
                  placeholder="Email"
                  pattern=".{3,}"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Col>
              <Col lg={6} className="pe-0">
                <input
                  maxLength="36"
                  type="text"
                  placeholder="Phone Number"
                  pattern=".{3,}"
                  required
                  value={phoneNum}
                  onChange={(event) => setPhoneNum(event.target.value)}
                />
              </Col>
            </Row>
            <Row lg={12} className="gy-4 w-100 p-0">
              <Col lg={6} className="ps-0">
                <input
                  maxLength="36"
                  type="name"
                  placeholder="Business Name"
                  pattern=".{3,}"
                  value={businessName}
                  onChange={(event) => setBusinessName(event.target.value)}
                />
              </Col>
              <Col lg={6} className="pe-0">
                <input
                  maxLength="36"
                  type="text"
                  placeholder="Your title/Role"
                  pattern=".{3,}"
                  value={role}
                  onChange={(event) => setRole(event.target.value)}
                />
              </Col>
            </Row>
            <input
              style={{ resize: "none" }}
              as="textarea"
              maxLength="800"
              type="text"
              placeholder="Please tell us a bit about what you're looking for"
              required
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <button className="button_main" type="submit">
              Submit <img src="/images/icons/arrow.svg" />
            </button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ContactPageForm;
