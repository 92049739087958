import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import BlogsPageSec from "../../Component/BlogsPageSec/BlogsPageSec";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Insights and Updates</title>
        <meta name="keywords" content="Software Company in Pakistan" />
        <meta name="keywords" content="Software Company in UAE" />
        <link rel="canonical" href="https://www.tekcorpltd.com/insights" />
        <meta
          name="description"
          content="Explore Our Latest Articles and Industry Trends"
        />
      </Helmet>
      <Hero
        subHeading={"Explore Our Latest Articles and Industry Trends"}
        heading={"Insights and Updates"}
      />
      <BlogsPageSec />
      <Footer />
    </>
  );
};

export default Blog;
