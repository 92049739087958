import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  listCaseStudyDetails,
  updateCaseStudy,
} from "../../store/actions/caseStudyActions";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";

const CaseStudyEditScreen = () => {
  const params = useParams();
  const caseStudyId = params.id;
  const dispatch = useDispatch();
  const history = useNavigate();

  const [heroHeading, setHeroHeading] = useState("");
  const [clientName, setClientName] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryText, setIndustryText] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("");
  const [category3, setCategory3] = useState("");
  const [category4, setCategory4] = useState("");
  const [template, setTemplate] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [video, setVideo] = useState("");
  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1Para, setSec1Para] = useState("");
  const [sec1Img, setSec1Img] = useState("");
  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Para, setSec2Para] = useState("");
  const [sec2Img, setSec2Img] = useState("");
  const [sec3Heading1, setSec3Heading1] = useState("");
  const [sec3SubHeading1, setSec3SubHeading1] = useState("");
  const [sec3Para1, setSec3Para1] = useState("");
  const [sec3Heading2, setSec3Heading2] = useState("");
  const [sec3SubHeading2, setSec3SubHeading2] = useState("");
  const [sec3Para2, setSec3Para2] = useState("");
  const [sec3Img2, setSec3Img2] = useState("");
  const [sec3Heading3, setSec3Heading3] = useState("");
  const [sec3SubHeading3, setSec3SubHeading3] = useState("");
  const [sec3Para3, setSec3Para3] = useState("");
  const [sec4Heading, setSec4Heading] = useState("");
  const [sec4SubHeading, setSec4SubHeading] = useState("");
  const [sec4Percentage1, setSec4Percentage1] = useState("");
  const [sec4Text1, setSec4Text1] = useState("");
  const [sec4Percentage2, setSec4Percentage2] = useState("");
  const [sec4Text2, setSec4Text2] = useState("");
  const [sec4Percentage3, setSec4Percentage3] = useState("");
  const [sec4Text3, setSec4Text3] = useState("");
  const [sec4Percentage4, setSec4Percentage4] = useState("");
  const [sec4Text4, setSec4Text4] = useState("");
  const [uploading, setUploading] = useState(false);

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);
  const { loading, error, caseStudy } = caseStudyDetails;

  const caseStudyUpdate = useSelector((state) => state.caseStudyUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = caseStudyUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_UPDATE_RESET" });

    if (!userInfo || !userInfo.isAdmin) {
      history("/login");
    }
    if (successUpdate) {
      history("/admin/casestudy");
      window.location.reload();
    } else {
      if (!caseStudy.heroHeading || caseStudy._id !== caseStudyId) {
        dispatch(listCaseStudyDetails(caseStudyId));
      } else {
        setHeroHeading(caseStudy.heroHeading);
        setClientName(caseStudy.clientName);
        setIndustry(caseStudy.industry);
        setIndustryText(caseStudy.industryText);
        setBannerImage(caseStudy.bannerImage);
        setVideo(caseStudy.video);
        setCategory1(caseStudy.category1);
        setCategory2(caseStudy.category2);
        setCategory3(caseStudy.category3);
        setCategory4(caseStudy.category4);
        setTemplate(caseStudy.template);
        setSec1Heading(caseStudy.sec1Heading);
        setSec1Para(caseStudy.sec1Para);
        setSec1Img(caseStudy.sec1Img);
        setSec2Heading(caseStudy.sec2Heading);
        setSec2Para(caseStudy.sec2Para);
        setSec2Img(caseStudy.sec2Img);
        setSec3Heading1(caseStudy.sec3Heading1);
        setSec3SubHeading1(caseStudy.sec3SubHeading1);
        setSec3Para1(caseStudy.sec3Para1);
        setSec3Heading2(caseStudy.sec3Heading2);
        setSec3SubHeading2(caseStudy.sec3SubHeading2);
        setSec3Para2(caseStudy.sec3Para2);
        setSec3Img2(caseStudy.sec3Img2);
        setSec3Heading3(caseStudy.sec3Heading3);
        setSec3SubHeading3(caseStudy.sec3SubHeading3);
        setSec3Para3(caseStudy.sec3Para3);
        setSec4Heading(caseStudy.sec4Heading);
        setSec4SubHeading(caseStudy.sec4SubHeading);
        setSec4Percentage1(caseStudy.sec4Percentage1);
        setSec4Text1(caseStudy.sec4Text1);
        setSec4Percentage2(caseStudy.sec4Percentage2);
        setSec4Text2(caseStudy.sec4Text2);
        setSec4Percentage3(caseStudy.sec4Percentage3);
        setSec4Text3(caseStudy.sec4Text3);
        setSec4Percentage4(caseStudy.sec4Percentage4);
        setSec4Text4(caseStudy.sec4Text4);
      }
    }
  }, [dispatch, history, caseStudy, caseStudyId, successUpdate, userInfo]);

  const uploadBannerHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setBannerImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadVideoHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setVideo(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg1Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setSec1Img(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg2Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setSec2Img(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg3Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setSec3Img2(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const formSubmitHander = (event) => {
    event.preventDefault();
    dispatch(
      updateCaseStudy({
        _id: caseStudyId,
        heroHeading,
        clientName,
        industry,
        industryText,
        bannerImage,
        video,
        category1,
        category2,
        category3,
        category4,
        template,
        sec1Heading,
        sec1Para,
        sec1Img,
        sec2Heading,
        sec2Para,
        sec2Img,
        sec3Heading1,
        sec3SubHeading1,
        sec3Para1,
        sec3Heading2,
        sec3SubHeading2,
        sec3Para2,
        sec3Img2,
        sec3Heading3,
        sec3SubHeading3,
        sec3Para3,
        sec4Heading,
        sec4SubHeading,
        sec4Percentage1,
        sec4Text1,
        sec4Percentage2,
        sec4Text2,
        sec4Percentage3,
        sec4Text3,
        sec4Percentage4,
        sec4Text4,
      })
    );
  };
  return (
    <Fragment>
      <Helmet>
      <meta name="robots" content="noindex" />
      </Helmet>
    <div className="pb-5 pt-5">
      <Container className="text-start">
        <Link to="/admin/casestudy" className="btn btn-dark my-3">
          Go Back
        </Link>
        <h1>Edit Case Study</h1>
        {loadingUpdate && <Spinner />}
        {errorUpdate && (
          <p className="para_main" style={{ color: "red" }}>
            {errorUpdate}
          </p>
        )}
        {loading ? (
          <Spinner />
        ) : error ? (
          <p className="para_main" style={{ color: "red" }}>
            {error}
          </p>
        ) : (
          <Form onSubmit={formSubmitHander}>
            <Form.Group className="mb-3 text-start" controlId="name">
              <Form.Label>Hero Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Hero Heading"
                value={heroHeading}
                onChange={(event) => setHeroHeading(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Client Name"
                value={clientName}
                onChange={(event) => setClientName(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Industry</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Industry"
                value={industry}
                onChange={(event) => setIndustry(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Industry Details</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Industry Details"
                value={industryText}
                onChange={(event) => setIndustryText(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="file">
              <Form.Label>Banner Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Image URL"
                value={bannerImage}
              ></Form.Control>
              <Form.Control
                type="file"
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadBannerHandler}
              ></Form.Control>
              {uploading && <Spinner />}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="file">
              <Form.Label>Testimonials Video</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Video URL"
                value={video}
                onChange={(e) => setVideo(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                id="file"
                label="Choose File"
                custom
                onChange={uploadVideoHandler}
              ></Form.Control>
              {uploading && <Spinner />}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 1 Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec1Heading}
                onChange={(event) => setSec1Heading(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 1 Paragraph</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec1Para}
                onChange={(event) => setSec1Para(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 1 Banner</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Image URL"
                value={sec1Img}
                onChange={(e) => setSec1Img(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadImg1Handler}
              ></Form.Control>
              {uploading && <Spinner />}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 2 Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec2Heading}
                onChange={(event) => setSec2Heading(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 2 Paragraph</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec2Para}
                onChange={(event) => setSec2Para(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 2 Banner</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Image URL"
                value={sec2Img}
                onChange={(e) => setSec2Img(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadImg2Handler}
              ></Form.Control>
              {uploading && <Spinner />}
            </Form.Group>
            
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Heading 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3Heading1}
                onChange={(event) => setSec3Heading1(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Sub-Heading 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3SubHeading1}
                onChange={(event) => setSec3SubHeading1(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Paragraph 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3Para1}
                onChange={(event) => setSec3Para1(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Heading 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3Heading2}
                onChange={(event) => setSec3Heading2(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Sub-Heading 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3SubHeading2}
                onChange={(event) => setSec3SubHeading2(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Paragraph 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3Para2}
                onChange={(event) => setSec3Para2(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Banner 2nd</Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter Image URL"
                value={sec3Img2}
                onChange={(e) => setSec3Img2(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadImg3Handler}
              ></Form.Control>
              {uploading && <Spinner />}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Heading 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3Heading3}
                onChange={(event) => setSec3Heading3(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Sub-Heading 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3SubHeading3}
                onChange={(event) => setSec3SubHeading3(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 3 Paragraph 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec3Para3}
                onChange={(event) => setSec3Para3(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Heading</Form.Label>
              <Form.Control
                type="text"
                required={true}
                placeholder="Enter Text"
                value={sec4Heading}
                onChange={(event) => setSec4Heading(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Sub-Heading</Form.Label>
              <Form.Control
                type="text"
                required={true}
                placeholder="Enter Text"
                value={sec4SubHeading}
                onChange={(event) => setSec4SubHeading(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Percentage 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Percentage1}
                onChange={(event) => setSec4Percentage1(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Text 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Text1}
                onChange={(event) => setSec4Text1(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Percentage 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Percentage2}
                onChange={(event) => setSec4Percentage2(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Text 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Text2}
                onChange={(event) => setSec4Text2(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Percentage 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Percentage3}
                onChange={(event) => setSec4Percentage3(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Text 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Text3}
                onChange={(event) => setSec4Text3(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Percentage 4</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Percentage4}
                onChange={(event) => setSec4Percentage4(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="text">
              <Form.Label>Section 4 Text 4</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Text"
                value={sec4Text4}
                onChange={(event) => setSec4Text4(event.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="category">
              <Form.Label>Select Template</Form.Label>
              <Form.Select
                type="text"
                value={template}
                onChange={(e) => setTemplate(e.target.value)}
              >
                <option value="template1">Template 1</option>
                <option value="template2">Template 2</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="category">
              <Form.Label>Category 1</Form.Label>
              <Form.Select
                type="text"
                value={category1}
                onChange={(e) => setCategory1(e.target.value)}
              >
                <option value="">Not selected</option>
                <option value="Web Development">Web Development</option>
                <option value="CMS Development">CMS Development</option>
                <option value="Ecommerce Development">Ecommerce Development</option>
                <option value="App Development">App Development</option>
                <option value="Landing Page">Landing Page</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Branding">Branding</option>
                <option value="Marketing Strategy">Marketing Strategy</option>
                <option value="Google Ads">Google Ads</option>
                <option value="SEO">SEO</option>
                <option value="SMM">SMM</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Reputation Management">
                  Reputation Management{" "}
                </option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Call Action Tracking">Call Action Tracking</option>
                <option value="CRM Integration">CRM Integration</option>
                <option value="API Integration">API Integration</option>
                <option value="Live Chat Solutions">Live Chat Solutions</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="category">
              <Form.Label>Category 2</Form.Label>
              <Form.Select
                type="text"
                value={category2}
                onChange={(e) => setCategory2(e.target.value)}
              >
                <option value="">Not selected</option>
                <option value="Web Development">Web Development</option>
                <option value="CMS Development">CMS Development</option>
                <option value="Ecommerce Development">Ecommerce Development</option>
                <option value="App Development">App Development</option>
                <option value="Landing Page">Landing Page</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Branding">Branding</option>
                <option value="Marketing Strategy">Marketing Strategy</option>
                <option value="Google Ads">Google Ads</option>
                <option value="SEO">SEO</option>
                <option value="SMM">SMM</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Reputation Management">
                  Reputation Management{" "}
                </option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Call Action Tracking">Call Action Tracking</option>
                <option value="CRM Integration">CRM Integration</option>
                <option value="API Integration">API Integration</option>
                <option value="Live Chat Solutions">Live Chat Solutions</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="category">
              <Form.Label>Category 3</Form.Label>
              <Form.Select
                type="text"
                value={category3}
                onChange={(e) => setCategory3(e.target.value)}
              >
                 <option value="">Not selected</option>
                <option value="Web Development">Web Development</option>
                <option value="CMS Development">CMS Development</option>
                <option value="Ecommerce Development">Ecommerce Development</option>
                <option value="App Development">App Development</option>
                <option value="Landing Page">Landing Page</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Branding">Branding</option>
                <option value="Marketing Strategy">Marketing Strategy</option>
                <option value="Google Ads">Google Ads</option>
                <option value="SEO">SEO</option>
                <option value="SMM">SMM</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Reputation Management">
                  Reputation Management{" "}
                </option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Call Action Tracking">Call Action Tracking</option>
                <option value="CRM Integration">CRM Integration</option>
                <option value="API Integration">API Integration</option>
                <option value="Live Chat Solutions">Live Chat Solutions</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="category">
              <Form.Label>Category 4</Form.Label>
              <Form.Select
                type="text"
                value={category4}
                onChange={(e) => setCategory4(e.target.value)}
              >
                  <option value="">Not selected</option>
                <option value="Web Development">Web Development</option>
                <option value="CMS Development">CMS Development</option>
                <option value="Ecommerce Development">Ecommerce Development</option>
                <option value="App Development">App Development</option>
                <option value="Landing Page">Landing Page</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Branding">Branding</option>
                <option value="Marketing Strategy">Marketing Strategy</option>
                <option value="Google Ads">Google Ads</option>
                <option value="SEO">SEO</option>
                <option value="SMM">SMM</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Reputation Management">
                  Reputation Management{" "}
                </option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Call Action Tracking">Call Action Tracking</option>
                <option value="CRM Integration">CRM Integration</option>
                <option value="API Integration">API Integration</option>
                <option value="Live Chat Solutions">Live Chat Solutions</option>
              </Form.Select>
            </Form.Group>
            <br></br>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </Container>
    </div>
    </Fragment>
  );
};

export default CaseStudyEditScreen;
