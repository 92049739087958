import React, { useEffect } from "react";
import styles from "./Portfolio.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listCaseStudy } from "../../store/actions/caseStudyActions";

const Portfolio = () => {
  const dispatch = useDispatch();

  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  const filtered =
    caseStudies && caseStudies.sort(() => Math.random()).slice(0, 6);

  useEffect(() => {
    dispatch(listCaseStudy());
  }, []);

  return (
    <div style={{ backgroundColor: "#fafafa" }} className="main">
      <Container>
        <Row className="gy-4 pb-4">
          <Col lg={10}>
            <div
              style={{ width: "100%", height: "100%" }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <h2
                style={{ alignSelf: "start" }}
                className="h2_main text-start pb-2"
              >
                <span>Portfolio_</span>
              </h2>
              <h1 style={{ alignSelf: "start" }} className="h1_main text-start">
                <span>High-performing </span>digital
                <br />
                <span>products</span>
              </h1>
            </div>
          </Col>
          <Col lg={2}>
            <Link style={{ textDecoration: "none" }} to="/case-studies">
              <div className={styles.projectsCont}>
                <div className={styles.projects}>
                  <h4
                    style={{ width: "100%", textAlign: "start" }}
                    className="h4_main"
                  >
                    View All
                  </h4>
                  <h4
                    style={{ width: "100%", textAlign: "end" }}
                    className="h4_main"
                  >
                    <span>Projects</span>
                  </h4>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
        {loading ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                color: "green",
                width: "120px",
                height: "120px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </span>
        ) : error ? (
          <p className="para_main" style={{ color: "red" }}>
            {error}
          </p>
        ) : (
          caseStudies.length !== 0 && (
            <>
              <Row className="gy-5 gx-lg-5 pt-5">
                <Col lg={6}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${filtered[0]._id}`}
                  >
                    <div className={styles.box}>
                      <img src={filtered[0].bannerImage} />
                      <p className="para_main pt-4">{filtered[0].category1}_</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "17px",
                        }}
                      >
                        <h2 className="h2_main pt-2">
                          {filtered[0].heroHeading}
                        </h2>
                        <img
                          style={{ width: "35px", height: "35px" }}
                          src="/images/icons/arrow_right_black.svg"
                        />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={6}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${filtered[1]._id}`}
                  >
                    <div className={styles.box}>
                      <img src={filtered[1].bannerImage} />
                      <p className="para_main pt-4">{filtered[1].category1}_</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "17px",
                        }}
                      >
                        <h2 className="h2_main pt-2">
                          {filtered[1].heroHeading}
                        </h2>
                        <img
                          style={{ width: "35px", height: "35px" }}
                          src="/images/icons/arrow_right_black.svg"
                        />
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
              <Row className="gy-5 gx-lg-4 pt-5">
                <Col lg={4}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${filtered[2]._id}`}
                  >
                    <div className={styles.box}>
                      <img src={filtered[2].bannerImage} />
                      <p className="para_main pt-4">{filtered[2].category1}_</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "17px",
                        }}
                      >
                        <h2 className="h2_main pt-2">
                          {filtered[2].heroHeading}
                        </h2>
                        <img
                          style={{ width: "35px", height: "35px" }}
                          src="/images/icons/arrow_right_black.svg"
                        />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={4}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${filtered[3]._id}`}
                  >
                    <div className={styles.box}>
                      <img src={filtered[3].bannerImage} />
                      <p className="para_main pt-4">{filtered[3].category1}_</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "17px",
                        }}
                      >
                        <h2 className="h2_main pt-2">
                          {filtered[3].heroHeading}
                        </h2>
                        <img
                          style={{ width: "35px", height: "35px" }}
                          src="/images/icons/arrow_right_black.svg"
                        />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={4}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${filtered[4]._id}`}
                  >
                    <div className={styles.box}>
                      <img src={filtered[4].bannerImage} />
                      <p className="para_main pt-4">{filtered[4].category1}_</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "17px",
                        }}
                      >
                        <h2 className="h2_main pt-2">
                          {filtered[4].heroHeading}
                        </h2>
                        <img
                          style={{ width: "35px", height: "35px" }}
                          src="/images/icons/arrow_right_black.svg"
                        />
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
              <Row className="gy-5 gx-lg-5 pt-5">
                <Col lg={12}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${filtered[5]._id}`}
                  >
                    <div
                      style={{
                        backgroundColor: "#f4f4f4",
                        padding: "30px",
                        borderRadius: "8px",
                      }}
                      className={styles.box}
                    >
                      <img src={filtered[5].bannerImage} />
                      <p className="para_main pt-4">{filtered[5].category1}_</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "17px",
                        }}
                      >
                        <h2 className="h2_main pt-2">
                          {filtered[5].heroHeading}
                        </h2>
                        <img
                          style={{ width: "35px", height: "35px" }}
                          src="/images/icons/arrow_right_black.svg"
                        />
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </>
          )
        )}
      </Container>
    </div>
  );
};
export default Portfolio;
