import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import BlogDetailView from "./BlogDetailView";
import PopularBlogs from "./PopularBlogs";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";

const Postdetails = () => {
  const [blogsData, setBlogsData] = useState({});
  const [popularBlogs, setPopularBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const convertToOriginalFormat = (slug) => {
    const words = slug.split("-");
    const originalString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return originalString;
  };

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blog`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let slug = convertToOriginalFormat(id);
          let blog = res.data.data.find((item) => item.slug_url == slug);
          if (!blog) {
            blog = res.data.data.find((item) => item.slug_url == id);
          }
          setBlogsData(blog);

          let Popular_blogs_data = [];
          res.data.data.forEach((item) => {
            Popular_blogs_data.push({
              blog_id: item.blog_id,
              heading: item.title,
              slug_url: item.slug_url,
              creative: item.blog_image,
              date: item.published_date,
            });
          });
          setPopularBlogs(Popular_blogs_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);

  const DetailModuleBlogs = () => {
    return (
      <Fragment>
        <Helmet>
          {blogsData.metaTitle && <title>{blogsData.metaTitle}</title>}
          {blogsData.metaDescription && (
            <meta name="description" content={blogsData.metaDescription} />
          )}
          {blogsData.canonicalLink && (
            <link rel="canonical" href={blogsData.canonicalLink}></link>
          )}
        </Helmet>
        <Hero heading={blogsData.title} />
        <BlogDetailView blog={blogsData} />
        <PopularBlogs popularBlogs={popularBlogs} />
        <Footer />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="spinner-styles"
        >
          <Spinner
            style={{ color: "green", width: "120px", height: "120px" }}
          />
        </div>
      ) : (
        DetailModuleBlogs()
      )}
    </Fragment>
  );
};

export default Postdetails;
