import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ImgContent.module.css";

const ImgContent = ({ heading, para, img, paddingBottom, paddingTop }) => {
  return (
    <div
      style={{ paddingBottom: paddingBottom, paddingTop: paddingTop }}
      className={styles.main}
    >
      <Container>
        <Row className="gy-5">
          <Col lg={12}>
            <div className={styles.contentCol}>
              <h1 className="h1_main">
                <span>{heading}</span>
              </h1>
              <p className="para_main text-center pt-3">{para}</p>
            </div>
          </Col>
          <Col lg={12}>
            <div className={styles.imgCont}>
              {img && <img className="img-fluid" src={img} alt="img"/>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ImgContent;
