export const data = [
  {
    heading: "Audience Insights",
    text: `Our experienced designers collect qualitative data through interviews, contextual inquiry, and usability testing combining it with quantitative data from analytics and surveys to create a system that prioritizes your audience's goals.`,
  },
  {
    heading: "Navigation Architecture",
    text: `If your audience is having difficulty finding your product difficult to use, the issue might be with how your information is arranged. We investigate consumers' true objectives and tasks, using audience journeys, landscape analysis, and testing to generate helpful, relevant taxonomies and better navigation.`,
  },
  {
    heading: "Data Visualization",
    text: `We've been transforming complex data into appealing dynamic images that deliver engaging, impacting tales. To create attractive data visualization experiences, we work with you through dynamic collaboration and a simplified co-design approach that keeps you in constant touch with our team.`,
  },
  {
    heading: "Custom UI Design",
    text: `We create visually attractive and brand-aligned interfaces that offer a great user experience on all devices. Our designers turn your imagination into visual reality and are experts in providing result-driven outcomes.`,
  },
  {
    heading: "UX Augmentation",
    text: `We have years of expertise in smoothly integrating into existing teams and implementing staff augmentation methods for both clients and agency partners. Working with us broadens your bandwidth and knowledge on demand.`,
  },
  {
    heading: "User Flow Optimization",
    text: `Starting with an existing product, our team optimizes user flows and decreases time on activities, removing friction, improving conversions, and making it more accessible and enjoyable to use.`,
  },
];
