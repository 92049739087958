import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";

const Privacy = () => {
  return (
    <>
      <Hero subHeading={""} heading={"Privacy Policy"} />
      <div>
        <div className="container pb-5">
          <div className="col">
            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">
                  INFORMATION WE COLLECT FROM YOU
                </h1>
                <p className="para_main">
                  In the course of your visits to our website or use of our
                  products and services, we may obtain the following information
                  about you: name, company name, email address, telephone
                  number, credit card details, billing address, geographic
                  location, IP address, survey responses, support queries, blog
                  comments and social media handles (together ‘Personal Data’).
                  Our services are not directed to persons under 18 and we do
                  not knowingly collect Personal Data from anyone under 18. If
                  we become aware that a child under 18 has provided us with
                  Personal Data, we will delete that information as quickly as
                  possible. If you are the parent or guardian of a child and you
                  believe they have provided us with Personal Data without your
                  consent, then please contact us. You can review, correct,
                  update or delete your Personal Data by contacting us directly
                  to do so.• The Company reserves the right to add to or
                  subtract from the list of Products authorized on Exhibit A
                  attached hereto with notice to the Agency.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">
                  HOW WE USE YOUR INFORMATION
                </h1>
                <p className="para_main">
                  Personally Identifiable Information: We use the information we
                  collect to deliver our services to you, including:
                  communicating with you, providing technical support, notifying
                  you of updates and offers, sharing useful content, measuring
                  customer satisfaction, diagnosing problems and providing you
                  with a personalised website experience. Marketing
                  communications are only sent to you if you have requested or
                  subscribed to them. You can opt out of our marketing
                  communications at any time by unsubscribing or emailing us and
                  your request will be actioned immediately. Non-Personally
                  Identifiable Information: We also use the information we
                  collect in aggregated and anonymised forms to improve our
                  services, including: administering our website, producing
                  reports and analytics, advertising our products and services,
                  identifying user demands and assisting in meeting customer
                  needs generally. Any information you choose to make publicly
                  available, such as blog comments and testimonials on our
                  website, will be available for others to see. If you
                  subsequently remove this information, copies may remain
                  viewable in cached and archived pages on other websites or if
                  others have copied or saved the information.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">
                  STORAGE AND SECURITY OF YOUR INFORMATION
                </h1>
                <p className="para_main">
                  We will use all reasonable means to protect the
                  confidentiality of your Personal Data while in our possession
                  or control. All information we receive from you is stored and
                  protected on our secure servers from unauthorized use or
                  access. Credit card information is encrypted before
                  transmission and is not stored by us on our servers. To enable
                  us to deliver our services, we may transfer information that
                  we collect about you, including Personal Data, across borders
                  for storage and processing in countries other than Australia.
                  If your Personal Data is transferred and processed outside
                  Australia, it will only be transferred to countries that have
                  adequate privacy protections. We retain your personal
                  information for as long as needed to provide services to you
                  and as otherwise necessary to comply with our legal
                  obligations, resolve disputes and enforce our agreements. In
                  the event there is a breach of our security and your Personal
                  Data is compromised, we will promptly notify you in compliance
                  with the applicable law.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">COOKIES AND PIXELS</h1>
                <p className="para_main">
                  A cookie is a small file placed in your web browser that
                  collects information about your web browsing behavior. The use
                  of cookies allows a website to tailor its configuration to
                  your needs and preferences. Cookies do not access information
                  stored on your computer or any Personal Data (e.g. name,
                  address, email address, or telephone number). Most web
                  browsers automatically accept cookies but you can choose to
                  reject cookies by changing your browser settings. This may,
                  however, prevent you from taking full advantage of our
                  website. Our website uses cookies to analyze website traffic,
                  provide social media sharing and liking functionality, and
                  help us provide a better website visitor experience. In
                  addition, cookies and pixels may be used to serve relevant ads
                  to website visitors through third party services such as
                  Google Adwords and Facebook Adverts. These ads may appear on
                  this website or other websites you visit.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">
                  SHARING YOUR INFORMATION WITH THIRD PARTIES
                </h1>
                <p className="para_main">
                  We do not and will not sell or deal in Personal Data or any
                  customer information. Your Personal Data details are only
                  disclosed to third party suppliers when it is required by law,
                  for goods or services which you have purchased, for payment
                  processing or to protect our copyright, trademarks, and other
                  legal rights. To the extent that we do share your Personal
                  Data with a service provider, we would only do so if that
                  party has agreed to comply with our privacy standards as
                  described in this privacy policy and in accordance with
                  applicable law. Our contracts with third parties prohibit them
                  from using any of your Personal Data for any purpose other
                  than that for which it was shared.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">
                  DISCLOSURE OF YOUR INFORMATION
                </h1>
                <p className="para_main">
                  We may from time to time need to disclose certain information,
                  which may include your Personal Data, to comply with a legal
                  requirement, such as a law, regulation, court order, subpoena,
                  warrant, in the course of a legal proceeding or in response to
                  a law enforcement agency request. Also, we may use your
                  Personal Data to protect the rights, property or safety of
                  TekCorp Limited, our customers or third parties. If there is a
                  change of control in one of our businesses (whether by merger,
                  sale, transfer of assets or otherwise) customer information,
                  which may include your Personal Data, could be transferred to
                  a purchaser under a confidentiality agreement. We would only
                  disclose your Personal Data in good faith and where required
                  by any of the above circumstances.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">LINKS TO OTHER WEBSITES</h1>
                <p className="para_main">
                  This website may contain links to other websites. These links
                  are meant for your convenience only. Links to third party
                  websites do not constitute sponsorship or endorsement or
                  approval of these websites. Please be aware that we are not
                  responsible for the privacy practices of such other websites.
                  We encourage our users to be aware, when they leave our
                  website, to read the privacy statements of each and every
                  website that collects personally identifiable information.
                  This privacy policy applies solely to information collected by
                  this website.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">CHANGE IN PRIVACY POLICY</h1>
                <p className="para_main">
                  As we plan to ensure our privacy policy remains current, this
                  policy is subject to change. We may modify this policy at any
                  time, at our sole discretion and all modifications will be
                  effective immediately upon our posting of the modifications on
                  this website. Please return periodically to review our privacy
                  policy.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">
                  WHAT RIGHTS YOU HAVE OVER YOUR DATA
                </h1>
                <p className="para_main">
                  You can also request that we erase any personal data we hold
                  about you. This does not include any data we are obliged to
                  keep for administrative, legal, or security purposes.
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <h1 className="h2_main pb-3 pt-5">CONTACT US</h1>
                <p className="para_main">
                  If you have any questions or concerns at any time about our
                  privacy policy or the use of your Personal Data, please
                  contact us <a href="mailto:support@tekcorpltd.com">here</a>{" "}
                  and we will respond within 48 hours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
