import { useState } from "react";
import { Link } from "react-router-dom";
import "./mobileNav.css";
import { Links1, Links2, Links3 } from "./Links";

const Mobilenav = (props) => {
  const [selected, setSelected] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [selected3, setSelected3] = useState(false);
  const [selected4, setSelected4] = useState(false);

  let transfer = props.transfer;
  const mobiledropdown = (state) => {
    setSelected(!state);
  };
  const mobiledropdown2 = (state) => {
    setSelected2(!state);
    setSelected3(false);
    setSelected4(false);
  };
  const mobiledropdown3 = (state) => {
    setSelected3(!state);
    setSelected2(false);
    setSelected4(false);
  };
  const mobiledropdown4 = (state) => {
    setSelected4(!state);
    setSelected3(false);
    setSelected2(false);
  };

  return (
    <>
      <div
        style={{ top: props.linear && "71px", zIndex: "9999999999" }}
        className={
          transfer === true ? "  mobilenav_cs1" : "  mobilenavclicked_cs1"
        }
      >
        <ul>
          <div className={props.landingPage && "d-none"}>
            <div
              onClick={() => mobiledropdown(selected)}
              className="d-flex justify-content-between align-content center navitems_cs1"
            >
              <li style={{ color: "white" }}>
                <a className="h4_main">Services</a>
              </li>
              <i
                class="fa-solid fa-chevron-up"
                style={{
                  transform:
                    selected === true ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "0.3s",
                  color: selected === true ? "#09BC9D" : "white",
                }}
              ></i>
            </div>
            <div
              className={
                selected === true ? "mobsubmenu_cs1 show" : "mobsubmenu_cs1"
              }
            >
              <div onClick={() => mobiledropdown2(selected2)}>
                <h6 className="d-flex justify-content-between align-content-center">
                  <li
                    style={{ color: selected2 === true ? "#09BC9D" : "white" }}
                  >
                    Design & Craft
                  </li>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      transform:
                        selected2 === true ? "rotate(90deg)" : "rotate(45deg)",
                      transition: "0.3s",
                      color: selected2 === true ? "#09BC9D" : "white",
                    }}
                  ></i>
                </h6>
                <div
                  className={
                    selected2 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul>
                    {Links1.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div onClick={() => mobiledropdown3(selected3)}>
                <h6 className="d-flex justify-content-between align-content-center">
                  <li
                    style={{ color: selected3 === true ? "#09BC9D" : "white" }}
                  >
                    Build & Promote
                  </li>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      transform:
                        selected3 === true ? "rotate(90deg)" : "rotate(45deg)",
                      transition: "0.3s",
                      color: selected3 === true ? "#09BC9D" : "white",
                    }}
                  ></i>
                </h6>
                <div
                  className={
                    selected3 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul id="ul2_cs1">
                    {Links2.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <div onClick={() => mobiledropdown4(selected4)}>
                <h6 className="d-flex justify-content-between align-content-center">
                  <li
                    style={{ color: selected4 === true ? "#09BC9D" : "white" }}
                  >
                    Portal Development
                  </li>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      transform:
                        selected4 === true ? "rotate(90deg)" : "rotate(45deg)",
                      transition: "0.3s",
                      color: selected4 === true ? "#09BC9D" : "white",
                    }}
                  ></i>
                </h6>
                <div
                  className={
                    selected4 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul id="ul2_cs1">
                    {Links3.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className={props.landingPage && "d-none"}>
            <div className="d-flex justify-content-between align-content center navitems_cs1">
              <li>
                <Link className="h4_main" to="/company">
                  Company
                </Link>
              </li>
            </div>
          </div>
          <div
            className={`d-flex justify-content-between align-content center navitems_cs1 ${
              props.landingPage && "d-none"
            }`}
          >
            <li>
              <Link className="h4_main" to="/case-studies">
                Case Studies
              </Link>
            </li>
          </div>
          <div
            className={`d-flex justify-content-between align-content center navitems_cs1 ${
              props.landingPage && "d-none"
            }`}
          >
            <li>
              <Link className="h4_main" to="/insights">
                Insights
              </Link>
            </li>
          </div>
          <div className={`d-flex justify-content-start mobilebtn_cs1`}>
            <Link style={{ width: "100%" }} target="no_blank" to="/contact">
              <button className="button2_cs_1 button_main">Get Started</button>
            </Link>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Mobilenav;
