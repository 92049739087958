import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import AboutSec from "../../Component/AboutSec/AboutSec";
import Partnerships from "../../Component/Partnerships/Partnerships";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About TekCorp</title>
        <meta name="keywords" content="Software Company in Pakistan" />
        <meta name="keywords" content="Software Company in UAE" />
        <link rel="canonical" href="https://www.tekcorpltd.com/company" />
        <meta
          name="description"
          content="Our mission is to empower businesses to achieve their
                    fullest potential through innovative, data-driven digital
                    marketing and software solutions. We are dedicated to
                    delivering exceptional results and driving meaningful growth
                    for our clients."
        />
      </Helmet>
      <Hero
        subHeading={"Big Ideas, Starts Here"}
        heading={"Skyrocket your Digital Transformation"}
      />
      <AboutSec />
      {/* <Partnerships /> */}
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default About;
