import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Reviews.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./Reviews.css";

const Reviews = () => {
  return (
    <div style={{ background: "#fafafa" }} className="main">
      <Container>
        <Row>
          <Col lg={8}>
            <h4 className="h4_main pb-2 text-start">
              <span>5 Stars on Google _</span>
            </h4>
            <h1 className="h1_small text-start">
              <span>Transforming </span> Visions into
              <br /> <span>pixel-perfect </span> Reality
            </h1>
          </Col>
          <Col lg={4}>
            <div
              style={{ width: "100%", height: "100%" }}
              className="d-flex justify-content-end align-items-center"
            >
              <a
                target="no_blank"
                href="https://maps.app.goo.gl/q6aZ5Krj4xZ35gss9"
              >
                <button className="button_simple black">See More</button>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="pt-5 gy-5">
          <Col lg={6}>
            <Row className={`gy-5 ${styles.boxCont}`}>
              <Col lg={6}>
                <div className={styles.box}>
                  <img src="/images/icons/review_star.svg" />
                  <div>
                    <h1 className="h1_main">
                      <span>7+</span>
                    </h1>
                    <h3 className="h3_main">Years of Experience</h3>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className={styles.box}>
                  <img src="/images/icons/review_medal.svg" />
                  <div>
                    <h1 className="h1_main">
                      <span>25+</span>
                    </h1>
                    <h3 className="h3_main">
                      Satisfied <br />
                      Clients
                    </h3>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <div className={styles.reviewCont}>
              <div className={styles.head}>
                <div className="text-start">
                  <h4 className="h4_main pb-2">
                    <span>Testimonials _</span>
                  </h4>
                  <h1 className="h2_main">
                    What <span>Our Clients </span>
                    Say
                  </h1>
                </div>
                <div className={styles.buttonsCont}>
                  <div className="custom-prev-button">
                    <img
                      style={{ rotate: "180deg" }}
                      src="/images/icons/arrow_right_black.svg"
                    />
                  </div>
                  <div className="custom-next-button">
                    <img src="/images/icons/arrow_right_black.svg" />
                  </div>
                </div>
              </div>
              <Swiper
                className="reviews_swiper"
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                loop
                navigation={{
                  nextEl: ".custom-next-button",
                  prevEl: ".custom-prev-button",
                }}
                spaceBetween={10}
                slidesPerView={1.3}
                centeredSlides={false}
                modules={[Navigation, Autoplay]}
              >
                <SwiperSlide>
                  <h2 className="h2_main">Moosa Khan</h2>
                  <p className="para_main pe-5">
                    I recently hired TekCorp to develop a website and LMS portal
                    for my business, and I couldn't be more satisfied with the
                    results...
                  </p>
                  <img src={"/images/icons/review_stars.svg"} />
                </SwiperSlide>
                <SwiperSlide>
                  <h2 className="h2_main">Muhammad Haris</h2>
                  <p className="para_main pe-5">
                    Tekcorp is a highly professional team. My website was
                    struggling to go live, and after reaching out to several
                    people who couldn’t help...
                  </p>
                  <img src={"/images/icons/review_stars.svg"} />
                </SwiperSlide>
                <SwiperSlide>
                  <h2 className="h2_main">Sumayya Ahmer</h2>
                  <p className="para_main pe-5">
                    I needed a fashion website with a unique user experience,
                    and Tekcorp's professional team, led by Taha, exceeded my
                    expectations...
                  </p>
                  <img src={"/images/icons/review_stars.svg"} />
                </SwiperSlide>
                <SwiperSlide>
                  <h2 className="h2_main">Tyre Fixing Services</h2>
                  <p className="para_main pe-5">
                    My website experienced a fatal error, causing one of the
                    pages to become inaccessible. Tekcorp Limited, under the
                    expert guidance...
                  </p>
                  <img src={"/images/icons/review_stars.svg"} />
                </SwiperSlide>
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reviews;
