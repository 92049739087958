import React, { Fragment, useEffect } from "react";
import ImgContent from "./ImgContent/ImgContent";
import HeadingContent from "./HeadingContent/HeadingContent";
import NextCaseStudies from "./NextCaseStudies/NextCaseStudies";
import PercentageTemp2 from "./PercentageTemp2/PercentageTemp2";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { listCaseStudyDetails } from "../../store/actions/caseStudyActions";
import { Spinner } from "react-bootstrap";
import TestimonialsSummary from "../../Component/TestimonialsSummary/TestimonialsSummary";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header/Header";

const IdvCaseStudy = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);
  const { error, loading, caseStudy } = caseStudyDetails;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_DETAILS_RESET" });
    dispatch(listCaseStudyDetails(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <div className="d-flex flex-column align-items-center justify-content-center text-center">
        <Header blackVariant={true} />
        {loading || !caseStudy ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <Spinner
              style={{
                color: "green",
                width: "120px",
                height: "120px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </div>
        ) : error ? (
          <p className="para_main" style={{ color: "red" }}>
            {error}
          </p>
        ) : (
          caseStudy &&
          !loading &&
          !error && (
            <>
              <TestimonialsSummary
                title1={caseStudy.heroHeading}
                industry={caseStudy.industry}
                industryText={caseStudy.industryText}
                category1={caseStudy.category1}
                category2={caseStudy.category2}
                category3={caseStudy.category3}
                category4={caseStudy.category4}
                casestudy={true}
                videoURL={caseStudy.video}
              />
              <ImgContent
                heading={caseStudy.sec1Heading}
                para={caseStudy.sec1Para}
                img={caseStudy.sec1Img}
                paddingBottom="0px"
              />
              <ImgContent
                heading={caseStudy.sec2Heading}
                para={caseStudy.sec2Para}
                img={caseStudy.sec2Img}
              />
              <HeadingContent
                heading={caseStudy.sec3Heading1}
                subHeading={caseStudy.sec3SubHeading1}
                para={caseStudy.sec3Para1}
              />

              {caseStudy.template === "template2" ? (
                <>
                  <HeadingContent
                    heading={caseStudy.sec3Heading2}
                    subHeading={caseStudy.sec3SubHeading2}
                    para={caseStudy.sec3Para2}
                    img={caseStudy.sec3Img2}
                  />
                  <HeadingContent
                    heading={caseStudy.sec3Heading3}
                    subHeading={caseStudy.sec3SubHeading3}
                    para={caseStudy.sec3Para3}
                    darkBackground={true}
                  />
                </>
              ) : (
                <>
                  <HeadingContent
                    heading={caseStudy.sec3Heading2}
                    subHeading={caseStudy.sec3SubHeading2}
                    para={caseStudy.sec3Para2}
                    darkBackground={true}
                  />
                  <HeadingContent
                    heading={caseStudy.sec3Heading3}
                    subHeading={caseStudy.sec3SubHeading3}
                    para={caseStudy.sec3Para3}
                  />
                </>
              )}
              {caseStudy.template === "template2" ? (
                <PercentageTemp2
                  subHeading={caseStudy.sec4SubHeading}
                  heading={caseStudy.sec4Heading}
                  percentage1={caseStudy.sec4Percentage1}
                  text1={caseStudy.sec4Text1}
                  percentage2={caseStudy.sec4Percentage2}
                  text2={caseStudy.sec4Text2}
                  percentage3={caseStudy.sec4Percentage3}
                  text3={caseStudy.sec4Text3}
                  temp="template2"
                />
              ) : (
                <PercentageTemp2
                  subHeading={caseStudy.sec4SubHeading}
                  heading={caseStudy.sec4Heading}
                  percentage1={caseStudy.sec4Percentage1}
                  text1={caseStudy.sec4Text1}
                  percentage2={caseStudy.sec4Percentage2}
                  text2={caseStudy.sec4Text2}
                  percentage3={caseStudy.sec4Percentage3}
                  text3={caseStudy.sec4Text3}
                  percentage4={caseStudy.sec4Percentage4}
                  text4={caseStudy.sec4Text4}
                  temp="template1"
                />
              )}
            </>
          )
        )}
        <ContactForm />
        <Footer />
      </div>
    </Fragment>
  );
};

export default IdvCaseStudy;
