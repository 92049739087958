import React from "react";
import Hero from "../../Component/Hero/Hero";
import Reviews from "../../Component/Reviews/Reviews";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import InfoSec from "../../Component/InfoSec/InfoSec";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import { Helmet } from "react-helmet";

const EcomDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>Ecommerce Development Service - TekCorp</title>
        <meta
          name="keywords"
          content="Ecommerce Development Company in Pakistan"
        />
        <meta name="keywords" content="Ecommerce Development Company in UAE" />
        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/ecommerce-development"
        />
        <meta
          name="description"
          content="We specialize in creating engaging eCommerce platforms using custom MERN and MEAN stacks, as well as Shopify. Our expertise ensures seamless user experiences that captivate and retain your audience."
        />
      </Helmet>
      <Hero
        subHeading={"Ensuring seamless digital experiences"}
        heading={"Revolutionizing Businesses with Robust E-Commerce Solutions"}
      />
      <InfoSec
        data={{
          highlightedWord: "Seamless Experience",
          mainHeading: "Effective Strategies",
          para: `In the rush of digital transformation, catching up with the right tools to boost your online business is essential. We  give you a leg up over the competition by fusing state-of-the-art technology with a user-friendly interface .`,
          subHeading1: "Intuitive Interface",
          subPara1: `We allow your clients go through your online store hassle-free, and that is made possible by the engaging experiences we provide.`,
          subHeading2: "Secure Payments",
          subPara2: `We use popular payment gateways like PayPal, and Stripe etc., providing fast checkouts, increases customer trust, and enables secure transactions.`,
          subHeading3: "Inventory Management",
          subPara3: `Upload your stock data to our auto-updating service, schedule your new orders using our software, and then activate our smart reordering feature to sidestep the empty stock situation or incessant stock addition.`,
        }}
      />
      <ImgByContent
        img={"/images/ecom1.jpg"}
        services={true}
        span={"Why "}
        number={"01"}
        heading={"Choose TekCorp for E-Commerce Development ?"}
        text={
          "We provide you with a conversion-optimized ecommerce website with high security, an easy user experience, and a comprehensive suite of trusted third-party connectors."
        }
        text2={
          "With years of experience in eCommerce web development, we've helped 10+ startups, SMEs, and merchants all over the world to achieve online success. Let our experts revolutionize your e-commerce website and enhance visibility."
        }
      />
      <ImgByContent
        img={"/images/ecom2.jpg"}
        reverse={true}
        services={true}
        span={"Boost "}
        number={"02"}
        heading={"Audience Engagement with Our Expertise"}
        text={
          "We specialize in creating engaging eCommerce platforms using custom MERN and MEAN stacks, as well as Shopify. Our expertise ensures seamless user experiences that captivate and retain your audience."
        }
        text2={
          "We are focused on delivery, support, and maintenance. We also provide seamless ecommerce mobile app solutions for various industries and we delight our customers with effective solutions."
        }
      />
      <ImgByContent
        img={"/images/ecom3.jpg"}
        services={true}
        span={"Explore "}
        number={"03"}
        heading={"your Online Potential with us"}
        text={
          "Boost your online sales with our top-tier e-commerce design and development services, customized to fit your needs. Let our experts create a captivating online store that drives conversions and maximizes your revenue."
        }
        text2={
          "At TekCorp, we use precise methodologies to provide the best results to our clients. We prioritize strategic planning, inventive design, smooth development, rigorous testing, and continual improvement."
        }
      />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default EcomDevelopment;
