import React, { useEffect, useState } from "react";
import styles from "./Hero.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Header/Header";

const Hero = ({ img, mobileImg, subHeading, heading }) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const words = heading.split(" ");
  const midpoint = Math.ceil(words.length / 2);

  return (
    <>
      <div className={styles.main}>
        <div
          style={{
            backgroundImage: matches ? `url('${img}')` : `url('${mobileImg}')`,
          }}
          className={styles.hero}
        >
          <Header blackVariant={true} />
          <div className={styles.inner}>
            <Container>
              <div className={styles.contentCont}>
                <>
                  <h3 className="h3_main">{subHeading}</h3>
                  <h1 className="heading_capital">
                    {words.slice(0, midpoint).join(" ")} <br />{" "}
                    <span>{words.slice(midpoint).join(" ")}</span>
                  </h1>
                </>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;
