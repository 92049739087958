import React from "react";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import Hero from "../../Component/Hero/Hero";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import UIUXSec from "../../Component/UIUXSec/UIUXSec";
import Specialization from "../../Component/Specialization/Specialization";
import { data } from "./data";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const UIUX = () => {
  return (
    <>
      <Helmet>
        <title>UI/UX Design Service - TekCorp</title>
        <meta name="keywords" content="UI/UX Design Company in Pakistan" />
        <meta name="keywords" content="UI/UX Design Company in UAE" />
        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/ui-ux-design"
        />
        <meta
          name="description"
          content="Our strategic approach to UI/UX design goes beyond aesthetics; we prioritize user-centric design concepts and link creative talent with your business objectives, ensuring that every product component contributes to your success and resonates with your target audience."
        />
      </Helmet>
      <Hero
        subHeading="Interfaces that elevate user experiences and drive results"
        heading="Cutting-Edge Prototyping & UI/UX Design Services"
      />
      <div style={{ background: "#f4f4f5" }} className="main">
        <ImgByContent
          tagline={"How does it work?"}
          heading={"Pushing the boundaries of <br/> Possibility With 4 Steps"}
          img={"/images/ui-ux1.png"}
          text={`In this fast-moving digital world, it is impossible to imagine a successful product without an engaging user experience. At TekCorp, we create intuitive, user-driven designs that not only meet your business goals but also captivate and engage your audience.`}
          reverse={true}
          pillButton={true}
          pillLink={"/contact"}
          pillText={"Learn More"}
        />
        <UIUXSec />
      </div>
      <ImgByContent
        tagline={"What's the process?"}
        heading={"Shaping experience with <br/> result generating process"}
        img={"/images/ui-ux2.png"}
        text={`Our strategic approach to UI/UX design goes beyond aesthetics; we prioritize user-centric design concepts and link creative talent with your business objectives, ensuring that every product component contributes to your success and resonates with your target audience.`}
        text2={`From the early wireframes and prototypes to the final polished interfaces, we endeavor to meet the highest quality standards so that your users can easily navigate and appreciate the digital world you've built. We follow a set pattern and develop attractive workflow images by using tools like FIGMA, ADOBE XD, ZEPLIN, and much more.`}
        pillButton={true}
        pillLink={"/contact"}
        pillText={"Learn More"}
      />
      <ImgByContent
        tagline={"What's the purpose?"}
        heading={"Empowering businesses with <br/> strategic UX process"}
        img={"/images/ui-ux3.png"}
        text={`Strategic planning is the most crucial step and for this, you need an expert on board who can rightly assess every aspect of the UX design services to align with your business goal, eventually impacting the sales.`}
        text2={`Our team of professionals knows how to analyze, identify, and improve the offerings to stand out from your competitors. INVISION, SKETCH, and UXPIN are some of the prototyping tools which we work with and generate amazing results.`}
        reverse={true}
        pillButton={true}
        pillLink={"/contact"}
        pillText={"Learn More"}
      />
      <Specialization data={data} />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default UIUX;
