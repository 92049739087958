import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./ContactForm.module.css";
import axios from "axios";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        name: name,
        email: email,
        message: message,
        phoneNum: "none",
        formType: "Main Contact page form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message Sent Successfully.");
          setLoading(false);
          setName("");
          setEmail("");
          setMessage("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Message failed to send, try again by reloading the page.");
          setLoading(false);
          setName("");
          setEmail("");
          setMessage("");
        }
      });
  };

  return (
    <div className={`${styles.main} main`}>
      <Container>
        <div className={styles.innerMain}>
          <Row className="gy-4 gx-lg-5">
            <Col lg={7}>
              <div className={styles.contentCol}>
                <h3 className="h3_main pb-2">— Contact Us</h3>
                <h1 className="h1_main pb-3">
                  Do you have any questions or would you like to know more about
                  our services?
                </h1>
                <p
                  style={{ color: "rgba(159, 159, 159, 1)" }}
                  className="para_main pb-lg-5 pb-3 pe-lg-5"
                >
                  Then don't hesitate to contact us. We are here to help and
                  answer all your questions about our dental services.
                </p>
                <p
                  style={{ color: "rgba(225, 225, 225, 1)" }}
                  className="para_main pt-lg-5 pb-3 pe-lg-5"
                >
                  At <span>TekCorp</span>, your reliable partner for Information
                  Technology from Pakistan, we are always ready to support you.
                </p>
                <div className={styles.socials}>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=61563448207086"
                  >
                    <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_4_1069)">
                        <path d="M12.1105 20.1437V11.2926H15.0803L15.5258 7.84217H12.1105V5.63957C12.1105 4.6409 12.3867 3.96032 13.8204 3.96032L15.646 3.95957V0.873372C15.3303 0.832344 14.2466 0.738281 12.9852 0.738281C10.3514 0.738281 8.54814 2.34598 8.54814 5.29784V7.84217H5.5694V11.2926H8.54814V20.1437H12.1105Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4_1069">
                          <rect
                            width="19.4054"
                            height="19.4054"
                            transform="translate(0.903229 0.738281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/tekcorpltd/"
                  >
                    <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.6765 18.2036V12.5178C17.6765 9.72346 17.0749 7.58887 13.8148 7.58887C12.243 7.58887 11.1951 8.4427 10.7682 9.25773H10.7294V7.84114H7.64392V18.2036H10.8652V13.0612C10.8652 11.7028 11.1175 10.4026 12.7864 10.4026C14.4358 10.4026 14.4552 11.9357 14.4552 13.1388V18.1842H17.6765V18.2036Z" />
                      <path d="M2.40512 7.84131H5.62642V18.2038H2.40512V7.84131Z" />
                      <path d="M4.0152 2.67871C2.98671 2.67871 2.15228 3.51314 2.15228 4.54163C2.15228 5.57011 2.98671 6.42395 4.0152 6.42395C5.04369 6.42395 5.87812 5.57011 5.87812 4.54163C5.87812 3.51314 5.04369 2.67871 4.0152 2.67871Z" />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/tekcorpltd/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className={styles.formCol}>
                <h3 className="h3_main pb-4">
                  <span>Let’s get in touch!</span>
                </h3>
                <form onSubmit={formSubmitHandler}>
                  <label for="name">Full Name</label>
                  <input
                    id="name"
                    maxLength="12"
                    type="text"
                    placeholder="Your Full Name"
                    pattern=".{3,}"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    className="mb-4"
                  />
                  <label for="email">Email Address</label>
                  <input
                    id="email"
                    maxLength="36"
                    type="email"
                    placeholder="Email"
                    pattern=".{3,}"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className="mb-4"
                  />
                  <label for="message">Message</label>
                  <textarea
                    id="message"
                    style={{ resize: "none" }}
                    as="textarea"
                    maxLength="800"
                    type="text"
                    rows={4}
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    className="mb-4"
                  />
                  {loading ? (
                    <div className="row mb-3">
                      <div className="col-12">
                        <p>
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "black" }}
                          />
                        </p>
                      </div>
                    </div>
                  ) : success ? (
                    <div className="row mb-3">
                      <div className="col-12">
                        <p className="para_main text-start">{success}</p>
                      </div>
                    </div>
                  ) : (
                    error && (
                      <div className="row mb-3">
                        <div className="col-12">
                          <p className="para_main text-start">{error}</p>
                        </div>
                      </div>
                    )
                  )}
                  <button className="button_main" type="submit">
                    Submit <img src="/images/icons/arrow.svg" />
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
