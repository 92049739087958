import React from "react";
import Portfolio from "../../Component/Portfolio/Portfolio";
import Footer from "../../Component/Footer/Footer";
import HomeHero from "../../Component/HomeHero/HomeHero";
import Specialization from "../../Component/Specialization/Specialization";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import Blogs from "../../Component/Blogs/Blogs";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Best Digital Solutions Company - Tekcorp Limited</title>
        <meta name="keywords" content="Software Company in Pakistan" />
        <meta name="keywords" content="Software Company in UAE" />
        <link rel="canonical" href="https://www.tekcorpltd.com/" />
        <meta
          name="description"
          content="Looking for a top-notch digital solutions company? TekCorp is dedicated to helping forward-thinking businesses achieve their goals through strategic and innovative approach. Our dynamic team of experts
                is committed to continuous innovation, ensuring that your
                business gains a competitive edge and thrives in today’s
                fast-paced market."
        />
      </Helmet>
      <HomeHero />
      <Specialization />
      <ImgByContent
        home={true}
        reverse={true}
        img={"/images/strategic_partners.jpg"}
        text={
          "Our strategy enables us to provide unique experiences that promote growth and success for all stakeholders. Let's use the power of digital transformation along with software solutions to reach new heights."
        }
        text2={
          "We’ve got you covered with our comprehensive plan and immediate guidance. Experts at TekCorp are here to assist with your business-related queries at every stage. We bridge the gap between vision and impact, creating next-generation solutions that push the boundaries of what is possible."
        }
        buttonText={"Let's connect"}
        buttonLink={"/contact"}
      />
      <Portfolio />
      <Blogs />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Home;
