import React from "react";
import styles from "./ImgByContent.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ImgByContent = ({
  appDev,
  services,
  number,
  pillButton,
  pillLink,
  pillText,
  home,
  maintenancePage,
  img,
  span,
  heading,
  text,
  text2,
  list,
  buttonText,
  buttonLink,
  tagline,
  reverse,
  background,
  appointmentButton,
}) => {
  return (
    <div
      style={{ background: background && background }}
      className={styles.main}
    >
      <Container>
        <Row
          style={{ flexDirection: reverse && "row-reverse" }}
          className="gy-4 gx-lg-5"
        >
          <Col lg={6}>
            <div className={styles.imgCol}>
              <img
                style={{ borderRadius: services && "8px" }}
                className="img-fluid"
                src={img}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.contentCol}>
              {tagline &&
                (pillButton ? (
                  <h3 className="h4_main">
                    <span>{tagline}</span>
                  </h3>
                ) : (
                  <h3 className="h3_main">
                    <span>{tagline}</span>
                  </h3>
                ))}
              {home && (
                <h1 className="h1_main">
                  <span>Our Strategic</span>
                  <br /> <span>Partners</span>
                </h1>
              )}
              {maintenancePage && (
                <h1 className="h1_main">
                  <span>Dedicated Account </span>
                  <br /> <span>Managers</span>
                </h1>
              )}
              {services && (
                <div
                  style={{
                    background: "#17191A",
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ color: "white" }} className="h2_main">
                    {number}
                  </h5>
                </div>
              )}
              {heading &&
                (pillButton ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: heading }}
                    className="h1_small"
                  />
                ) : (
                  <h2 className="h2_main pb-2">
                    <span>{span}</span> {heading}
                  </h2>
                ))}
              <p className="para_main">{text}</p>
              {text2 && <p className="para_main pb-3">{text2}</p>}
              {buttonText && (
                <Link to={buttonLink}>
                  <button className="button_main grey">
                    {buttonText} <img src="/images/icons/arrow.svg" />
                  </button>
                </Link>
              )}
              {pillText && (
                <Link to={pillLink}>
                  <div className={styles.pillButton}>
                    <h4 className="h4_main ps-3">{pillText}</h4>
                    <img src="/images/icons/arrow_black.svg" />
                  </div>
                </Link>
              )}
              {appointmentButton && (
                <Link to="/contact">
                  <button
                    className={`button_main ${list && "mt-3"} appointment`}
                  >
                    <img src="/images/icons/add.svg" />
                    MAKE APPOINTMENT
                  </button>
                </Link>
              )}
              {appDev && (
                <Row className="w-100 pt-4 gy-5">
                  <Col lg={6}>
                    <div style={{ width: "100%" }}>
                      <h1 className="h1_small">3+</h1>
                      <h4 className="h4_main pt-2">Happy Clients</h4>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div style={{ width: "100%" }}>
                      <h1 className="h1_small">6+</h1>
                      <h4 className="h4_main pt-2">Optimized Apps</h4>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div style={{ width: "100%" }}>
                      <h1 className="h1_small">15+</h1>
                      <h4 className="h4_main pt-2">Feature Enhancements</h4>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div style={{ width: "100%" }}>
                      <h1 className="h1_small">3+</h1>
                      <h4 className="h4_main pt-2">Apps Delivered</h4>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ImgByContent;
