import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import ContactPageForm from "../../Component/ContactPageForm/ContactPageForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <link rel="canonical" href="https://www.tekcorpltd.com/contact" />
        <meta name="description" content="Get in Touch with Us" />
      </Helmet>
      <Hero
        subHeading={"Reach Out for Expert Assistance"}
        heading={"Get in Touch with Us"}
      />
      <ContactPageForm />
      <Footer />
    </>
  );
};

export default Contact;
