import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./AppDevSec.module.css";

const AppDevSec = () => {
  return (
    <div style={{ background: "#fafafa" }} className="main">
      <Container>
        <Row
          style={{ borderBottom: "1px solid #94A3B8" }}
          className="pb-5 gy-5"
        >
          <Col lg={6}>
            <div className={styles.numberCol}>
              <img src="/images/icons/1.svg" />
              <div>
                <h1 className="h1_small pb-4">PRODUCT</h1>
                <h1 className="h1_small">INTERPRETATION</h1>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <p className="para_main">
              Our business analysts are excellent at turning your goods and
              services into effective technical solutions.
              <br />
              <br />
              In close cooperation with you, we examine industry trends and
              client demands to guarantee we offer a mobile app that exactly
              matches your company goals. Our professionals know how to cater to
              your requirements and deliver them accordingly.
              <br />
              <br />
              We deeply analyze your product before undergoing any process and
              once we are done and satisfied with the details then we fully
              prepare our plan for your application solution.
            </p>
          </Col>
        </Row>
        <Row
          style={{ borderBottom: "1px solid #94A3B8" }}
          className="pt-5 pb-5 gy-5"
        >
          <Col lg={6}>
            <div className={styles.numberCol}>
              <img src="/images/icons/2.svg" />
              <div>
                <h1 className="h1_small pb-4">DESIGN</h1>
                <h1 className="h1_small">PHASE</h1>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <p className="para_main">
              UI/UX designing is a crucial phase in application development. We
              start by building a comprehensive user avatar that reflects the
              spirit of your target audience.
              <br />
              <br />
              Based on these data, our designers create visually appealing and
              intuitive app interfaces prioritizing user experience. The fluid
              navigation always engages and delights users. We design such user
              experiences which shape the digital journeys of our clients.
              <br />
              <br />
              Our team develops attractive user experiences by combining
              professional engineering and outstanding creativity. We
              collaborate with you on all aspects of your project, from
              gathering initial needs to day-to-day service administration.
            </p>
          </Col>
        </Row>
        <Row
          style={{ borderBottom: "1px solid #94A3B8" }}
          className="pt-5 pb-5 gy-5"
        >
          <Col lg={6}>
            <div className={styles.numberCol}>
              <img src="/images/icons/3.svg" />
              <div>
                <h1 className="h1_small pb-4">DEVELOPMENT</h1>
                <h1 className="h1_small">PHASE</h1>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <p className="para_main">
              Our development services are the foundation for efficient and
              scalable systems. We use cutting-edge server-side technologies to
              ensure applications run smoothly while dealing with complicated
              logic and big data quantities. Using Node.js and Python, we design
              and construct secure, robust, and fast-moving backend systems.
              <br />
              <br />
              Our backend development services are designed to meet all your
              demands, including data administration, user identification, and
              API connections.
            </p>
          </Col>
        </Row>
        <Row
          style={{ borderBottom: "1px solid #94A3B8" }}
          className="pt-5 pb-5 gy-5"
        >
          <Col lg={6}>
            <div className={styles.numberCol}>
              <img src="/images/icons/4.svg" />
              <div>
                <h1 className="h1_small pb-4">GOING</h1>
                <h1 className="h1_small">LIVE</h1>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <p className="para_main">
              After an in-depth plan, carrying out the development, and testing,
              we come to the most exciting stage: launching the application.
              <br />
              <br />
              We make sure that your launch is a smooth success and has a
              positive impact on the overall performance of your application.
              Our dedicated team ensures your app stays updated, optimized, and
              secure for the best user experience.
              <br />
              <br />
              Our launch procedure is planned as flawlessly as possible,
              letting no downtime remain and maximizing the effect. Through our
              knowledge, your application launch is just the first step in its
              journey to a successful outcome.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppDevSec;
