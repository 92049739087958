import React from "react";
import Hero from "../../Component/Hero/Hero";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import CaseStudies from "../../Component/CaseStudies/CaseStudies";
import { Helmet } from "react-helmet";

const CaseStudy = () => {
  return (
    <>
      <Helmet>
        <title>What Our Clients Say</title>
        <meta name="keywords" content="Software Company in Pakistan" />
        <meta name="keywords" content="Software Company in UAE" />
        <link rel="canonical" href="https://www.tekcorpltd.com/case-studies" />
        <meta
          name="description"
          content=" We strive to be the catalyst that propels businesses to
                    unprecedented heights. We are committed to pushing
                    boundaries and setting new standards in success."
        />
      </Helmet>
      <Hero
        subHeading={"Leading the Way in Tech Solutions"}
        heading={"Discover How TekCorp Innovates Solutions"}
      />
      <CaseStudies />
      <ContactForm />
      <Footer />
    </>
  );
};

export default CaseStudy;
