export const maintenanceData = [
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Level 1",
    text: `Lorem ipsum dolor sit amet, consectr sed do eius teor incididunt.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Level 1",
    text: `Lorem ipsum dolor sit amet, consectr sed do eius teor incididunt.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Level 1",
    text: `Lorem ipsum dolor sit amet, consectr sed do eius teor incididunt.`,
  },
];
