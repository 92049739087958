import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./UIUXSec.module.css";

const UIUXSec = () => {
  return (
    <div>
      <Container>
        <Row className="gy-5 pt-5">
          <Col lg={3}>
            <div className={styles.box}>
              <img src="/images/icons/rotated_arrows_white.svg" />
              <h2 className="h2_main">Discovery</h2>
              <p className="para_main pb-4">
                Mastering the art of UI/UX design, we deeply research and gather
                insights about each design we make.
              </p>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.box}>
              <img src="/images/icons/rotated_arrows_white.svg" />
              <h2 className="h2_main">Wireframe</h2>
              <p className="para_main pb-4">
                We map out the structure and layout of your interface, our
                professionals focus on functionality and user flow.
              </p>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.box}>
              <img src="/images/icons/rotated_arrows_white.svg" />
              <h2 className="h2_main">Mood Board</h2>
              <p className="para_main pb-4">
                We put together a palette of colors, typography, imagery, and
                textures that reflects your brand identity.
              </p>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.box}>
              <img src="/images/icons/rotated_arrows_white.svg" />
              <h2 className="h2_main">Design</h2>
              <p className="para_main pb-4">
                Our team then finally develop a product that is visually
                appealing and functionally efficient.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UIUXSec;
