export const webData = [
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Custom Website",
    text: `Crafting high-performance websites that elevate your online presence.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Tool Mastery",
    text: `We harness the power of the best tools, to enhance performance and security.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Design Mastery",
    text: `Creating dynamic, user-friendly websites working across all devices.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Optimized Engagement",
    text: `Ensuring a conversion-optimized experience for every user and driving results.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Accelerating Growth",
    text: `Using right strategies for specific targeted market to generate conversions.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Innovative Engineering",
    text: `Utilizing advanced tools and frameworks to provide future-proof solutions.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Security Enhancement",
    text: `Implementing a security framework to protect the application from any risks.`,
  },
  {
    img: "/images/icons/rotated_arrows.svg",
    heading: "Continuous Support",
    text: `We maintain your web application to function optimally all the time.`,
  },
];
