import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./BlogsPageSec.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function TruncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + "...";
  }
}

const BlogsPageSec = () => {
  const [blogs, setBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  let blogsAPICalled = false;

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = () => {
    if (blogsAPICalled) return;
    blogsAPICalled = true;

    setLoading(true);
    axios
      .get("/blog", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data = [];
          res.data.data.forEach((element) => {
            Updated_data.push({
              id: element.blog_id,
              slug_url: element.slug_url,
              heading: element.title,
              paragraph: element.brief_paragraph,
              truncatedParagraph: TruncateText(element.brief_paragraph, 250),
              date: element.published_date,
              image: element.blog_image,
            });
          });
          setBlogs(Updated_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <div className="main">
      <Container>
        <>
          <h1 className="h1_small pb-4">
            <span>Trending Blogs</span>
          </h1>
          {loading ? (
            <div className="w-100 text-center mb-5">
              <Spinner
                style={{ color: "green", width: "120px", height: "120px" }}
              />
            </div>
          ) : (
            <Row className="gy-4">
              {blogs.map((item, index) => (
                <>
                  {index % 3 === 0 ? (
                    <Col lg={5}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/blogs/${convertToSlug(item.slug_url)}`}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${item.image})`,
                          }}
                          className={styles.box}
                        >
                          <div className={styles.overlay}>
                            <div className={styles.head}>
                              <img src="/images/icons/arrow_black.svg" />
                            </div>
                            <div className={styles.foot}>
                              <div>
                                <h4 className="h4_main pb-2">
                                  Information Technology
                                </h4>
                                <h3 className="h3_main">
                                  {item.heading}
                                </h3>
                              </div>
                              <div className={styles.pill}>
                                <h4
                                  style={{ color: "black" }}
                                  className="h4_main"
                                >
                                  {item.date}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ) : (
                    <Col lg={7}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/blogs/${convertToSlug(item.slug_url)}`}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${item.image})`,
                          }}
                          className={styles.box}
                        >
                          <div className={styles.overlay}>
                            <div className={styles.head}>
                              <img src="/images/icons/arrow_black.svg" />
                            </div>
                            <div className={styles.foot}>
                              <div>
                                <h4 className="h4_main pb-2">
                                  Information Technology
                                </h4>
                                <h3 className="h3_main">
                                  {item.heading}
                                </h3>
                              </div>
                              <div className={styles.pill}>
                                <h4
                                  style={{ color: "black" }}
                                  className="h4_main"
                                >
                                  {item.date}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )}
                </>
              ))}
              {/* <Col lg={7}>
                <div
                  style={{
                    backgroundImage: "url(/images/blog1.png)",
                  }}
                  className={styles.box}
                >
                  <div className={styles.overlay}>
                    <div className={styles.head}>
                      <img src="/images/icons/arrow_black.svg" />
                    </div>
                    <div className={styles.foot}>
                      <div>
                        <h4 className="h4_main pb-2">Fish & Tips, Places</h4>
                        <h3 className="h3_main">
                          How to Go Salmon Fishing in Washington: An Angler's
                          Guide
                        </h3>
                      </div>
                      <div className={styles.pill}>
                        <h4 style={{ color: "black" }} className="h4_main">
                          May 23, 2023
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div
                  style={{
                    backgroundImage: "url(/images/blog1.png)",
                  }}
                  className={styles.box}
                >
                  <div className={styles.overlay}>
                    <div className={styles.head}>
                      <img src="/images/icons/arrow_black.svg" />
                    </div>
                    <div className={styles.foot}>
                      <div>
                        <h4 className="h4_main pb-2">Fish & Tips, Places</h4>
                        <h3 className="h3_main">
                          How to Go Salmon Fishing in Washington: An Angler's
                          Guide
                        </h3>
                      </div>
                      <div className={styles.pill}>
                        <h4 style={{ color: "black" }} className="h4_main">
                          May 23, 2023
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          )}
        </>
      </Container>
    </div>
  );
};

export default BlogsPageSec;
