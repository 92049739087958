import React from "react";
import Hero from "../../Component/Hero/Hero";
import Reviews from "../../Component/Reviews/Reviews";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import InfoSec from "../../Component/InfoSec/InfoSec";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import { Helmet } from "react-helmet";

const GoogleAds = () => {
  return (
    <>
      <Helmet>
        <title>Google Ads Service - TekCorp</title>
        <meta
          name="keywords"
          content="Google Ads Company in Pakistan"
        />
        <meta name="keywords" content="Google Ads Company in UAE" />
        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/google-ads"
        />
        <meta
          name="description"
          content="TekCorp stands out as a leading Google Ads management company, specializing in creating customer-centric, ROI-focused pay-per-click campaigns. Our in-depth market research and expertise enable us to craft Google Ads campaigns that target your ideal customers precisely when they are most ready to convert."
        />
      </Helmet>
      <Hero
        subHeading={
          "Maximize Reach and Drive Results with Precision Ad Campaigns"
        }
        heading={"Enhancing Visibility with Targeted Google Ads"}
      />
      <InfoSec
        data={{
          highlightedWord: "Empowering Businesses,",
          mainHeading: "Increasing Growth",
          para: `Do you want to rank on the 1st page of Google search when consumers search for your services and products? We’ve got the expertise to make it happen.`,
          subHeading1: "Conversion Tracking",
          subPara1: `We employ sound conversion tracking to determine the results of your ads, thus, giving you detailed insights.`,
          subHeading2: "Remarketing",
          subPara2: `We help you re-engage with visitors who have expressed interest in your products or services, thus, raising the chances of conversion.`,
          subHeading3: "Strategic Ad Campaigns",
          subPara3: `At TekCorp, our experts craft tailored Google Ads strategies to meet your business goals. We conduct comprehensive market research to identify the most relevant keywords, target audience segments, and optimal bidding strategies, ensuring your ads reach the right people at the right time.`,
        }}
      />
      <ImgByContent
        img={"/images/googleads1.jpg"}
        services={true}
        span={"Why Choose "}
        number={"01"}
        heading={"TekCorp for Your Google Ads Success?"}
        text={
          "When leveraging Google Ads, marketers invest in displaying advertisements across Google's vast network. These ads can feature various offerings, from services and product listings to promotional content. By targeting users through Google's search engine and its extensive advertising network, these ads appear precisely when individuals search for relevant terms, maximizing visibility and engagement."
        }
        text2={
          "Our certified experts leverage their deep understanding of current trends and our status as a Google Partner agency to craft tailored strategies that align with your goals. Utilizing a range of Google Ads formats, advanced targeting tools, and extensive reach, we design impactful campaigns that effectively engage your target audience in key locations."
        }
      />
      <ImgByContent
        img={"/images/googleads2.jpg"}
        reverse={true}
        services={true}
        span={"Maximizing Ad "}
        number={"02"}
        heading={"Reach Through Strategic Research and Market Insights"}
        text={
          "TekCorp stands out as a leading Google Ads management company, specializing in creating customer-centric, ROI-focused pay-per-click campaigns. Our in-depth market research and expertise enable us to craft Google Ads campaigns that target your ideal customers precisely when they are most ready to convert."
        }
        text2={
          "Partner with us to achieve your marketing goals and harness the full power of Google Ads. Our tailored solutions ensure your brand gains the exposure, engagement, and leads necessary to thrive on a global scale."
        }
      />
      <ImgByContent
        img={"/images/googleads3.jpg"}
        services={true}
        span={"Comprehensive "}
        number={"03"}
        heading={"Google Ads Monitoring"}
        text={
          "At TekCorp, we prioritize continuous monitoring and optimization to ensure your campaigns consistently achieve optimal performance and deliver the results your business needs to thrive."
        }
        text2={
          "Our state-of-the-art tools enable real-time tracking of your Google Ads campaigns, allowing us to swiftly identify any performance fluctuations, such as changes in click-through rates (CTR), conversion rates, and cost-per-click (CPC). With our proactive approach, we can quickly address any issues, make budget-conscious adjustments, and keep your campaigns on track for success."
        }
      />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default GoogleAds;
