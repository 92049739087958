import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./CaseStudies.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listCaseStudy } from "../../store/actions/caseStudyActions";

const CaseStudies = () => {
  const dispatch = useDispatch();
  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  useEffect(() => {
    dispatch(listCaseStudy());
  }, []);

  return (
    <div className="main">
      <Container>
        <Row className="gy-5 gx-lg-5 pt-5">
          {loading ? (
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  color: "green",
                  width: "120px",
                  height: "120px",
                  alignSelf: "center",
                  textAlign: "center",
                }}
              />
            </span>
          ) : error ? (
            <p className="para_main" style={{ color: "red" }}>
              {error}
            </p>
          ) : (
            caseStudies.length !== 0 &&
            caseStudies.map((item, index) => (
              <Col key={index} lg={6}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/casestudy/${item._id}`}
                >
                  <div className={styles.box}>
                    <img
                      className={styles.bannerImage}
                      src={item.bannerImage}
                    />
                    <p className="para_main pt-4">{item.category1}_</p>
                    <h2 className="h1_small pt-2 pb-4">
                      <span>{item.heroHeading}</span>
                    </h2>
                    <div className={styles.footer}>
                      <h4 className="h4_main ps-3">View Details</h4>
                      <img src="/images/icons/arrow_black.svg" />
                    </div>
                  </div>
                </Link>
              </Col>
            ))
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CaseStudies;
