import React from "react";
import styles from "./PercentageTemp2.module.css";
import { Col, Container, Row } from "react-bootstrap";

const PercentageTemp2 = ({
  heading,
  subHeading,
  percentage1,
  text1,
  percentage2,
  text2,
  percentage3,
  text3,
  percentage4,
  text4,
  temp,
  paddingBottom,
  paddingTop,
}) => {
  return (
    <div className={styles.main}>
      <Container>
        <div className={styles.headingCont}>
          <h4 className="h4_main">{subHeading}</h4>
          <h1 className="heading_capital">
            <span>{heading}</span>
          </h1>
        </div>
        <Row className="mt-lg-3 mt-2 gy-3">
          {temp === "template2" ? (
            <>
              <Col lg={4}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage1}</span>
                  </h1>
                  <h4 className="h4_main">{text1}</h4>
                </div>
              </Col>
              <Col lg={4}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage2}</span>
                  </h1>
                  <h4 className="h4_main">{text2}</h4>
                </div>
              </Col>
              <Col lg={4}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage3}</span>
                  </h1>
                  <h4 className="h4_main">{text3}</h4>
                </div>
              </Col>
            </>
          ) : (
            <>
             <Col lg={3}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage1}</span>
                  </h1>
                  <h4 className="h4_main">{text1}</h4>
                </div>
              </Col>
              <Col lg={3}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage2}</span>
                  </h1>
                  <h4 className="h4_main">{text2}</h4>
                </div>
              </Col>
              <Col lg={3}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage3}</span>
                  </h1>
                  <h4 className="h4_main">{text3}</h4>
                </div>
              </Col>
              <Col lg={3}>
                <div className={styles.box}>
                  <h1 className="heading_capital">
                    <span>{percentage4}</span>
                  </h1>
                  <h4 className="h4_main">{text4}</h4>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PercentageTemp2;
