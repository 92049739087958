import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Blogs.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function TruncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + "...";
  }
}

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  let blogsAPICalled = false;

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = () => {
    if (blogsAPICalled) return;
    blogsAPICalled = true;

    setLoading(true);
    axios
      .get("/blog", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data = [];
          res.data.data.forEach((element) => {
            Updated_data.push({
              id: element.blog_id,
              slug_url: element.slug_url,
              heading: element.title,
              paragraph: element.brief_paragraph,
              truncatedParagraph: TruncateText(element.brief_paragraph, 150),
              date: element.published_date,
              image: element.blog_image,
            });
          });
          setBlogs(Updated_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <div className="main">
      <Container>
        <Row className="pb-4">
          <Col lg={12}>
            <div
              style={{ width: "100%", height: "100%" }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <h2
                style={{ alignSelf: "start" }}
                className="h2_main text-start pb-2"
              >
                <span>Latest Blogs_</span>
              </h2>
              <h1 style={{ alignSelf: "start" }} className="h1_main text-start">
                <span>Our Excited Arti</span>cles you
                <br />
                <span>maybe Interested in</span>
              </h1>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div className="w-100 text-center mb-5">
            <Spinner
              style={{ color: "green", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          <Row className="gy-5 gx-lg-4 pt-4">
            <Col lg={4}>
              <div className={styles.boxCont}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/blogs/${convertToSlug(blogs[0].slug_url)}`}
                >
                  <div className={styles.box}>
                    <img src={blogs[0].image} />
                    <div className={styles.contentCont}>
                      <p className="para_main pt-1">{blogs[0].date}</p>
                      <h2 className="h2_main pt-2">{blogs[0].heading}</h2>
                      <p className="para_main pt-2">
                        {blogs[0].truncatedParagraph}
                      </p>
                      <div className={styles.link}>
                        <Link to={`/blogs/${convertToSlug(blogs[2].slug_url)}`}>
                          Read more
                        </Link>
                        <img src="/images/icons/arrow_black.svg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.boxCont}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/blogs/${convertToSlug(blogs[1].slug_url)}`}
                >
                  <div className={styles.box}>
                    <img src={blogs[1].image} />
                    <div className={styles.contentCont}>
                      <p className="para_main pt-1">{blogs[1].date}</p>
                      <h2 className="h2_main pt-2">{blogs[1].heading}</h2>
                      <p className="para_main pt-2">
                        {blogs[1].truncatedParagraph}
                      </p>
                      <div className={styles.link}>
                        <Link to={`/blogs/${convertToSlug(blogs[2].slug_url)}`}>
                          Read more
                        </Link>
                        <img src="/images/icons/arrow_black.svg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.boxCont}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/blogs/${convertToSlug(blogs[2].slug_url)}`}
                >
                  <div className={styles.box}>
                    <img src={blogs[2].image} />
                    <div className={styles.contentCont}>
                      <p className="para_main pt-1">{blogs[2].date}</p>
                      <h2 className="h2_main pt-2">{blogs[2].heading}</h2>
                      <p className="para_main pt-2">
                        {blogs[2].truncatedParagraph}
                      </p>
                      <div className={styles.link}>
                        <Link to={`/blogs/${convertToSlug(blogs[2].slug_url)}`}>
                          Read more
                        </Link>
                        <img src="/images/icons/arrow_black.svg" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Blogs;
