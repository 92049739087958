import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";

const Refund = () => {
  const refundEligibility = [
    "Frequent unmet deadlines from our production team's end.",
    "Miscommunication by key account managers or salespeople",
    "Lack of performance by the team.",
    "The project is not being initiated by the team",
    "Other work-related delays",
  ];

  const refundNotProvided = [
    "Customer's change of heart.",
    "Delay from customer's end",
    "The project is completed and uploaded on the server.",
    "Asking for more than what's committed on the agreement.",
  ];
  return (
    <>
      <Hero subHeading={""} heading={"Refund Policy"} />
      <section className="pt-5 pb-5 ContentRefund container text-start">
        <div className="row pb-3">
          <div className="col-12">
            <p className="para_main">
              At TekCorp Limited, we ensure 100% customer satisfaction. In case
              of any dissatisfaction or disappointment faced by our customers,
              we offer a complete refund on certain terms and conditions. Our
              policy has been devised to define under which conditions we will
              be offering a refund. By registering for any of TekCorp Limited
              services you are declaring that you accept and agree with all the
              terms and conditions outlined in the refund policy
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="h2_main pt-3 pb-3">ELIGIBILITY FOR REFUND</h1>
            {refundEligibility.map((eligibility, index) => (
              <p className="para_main" key={index}>
                <i class="fa-solid fa-chevron-right"></i> {eligibility}
              </p>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h1 className="h2_main pt-5 pb-3">
              REFUND WILL NOT BE PROVIDED IN CASE OF
            </h1>
            {refundNotProvided.map((notProvided, index) => (
              <p className="para_main" key={index}>
                <i class="fa-solid fa-chevron-right"></i> {notProvided}
              </p>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h1 className="h2_main pt-5 pb-3">PROCESSING OF REFUNDS</h1>
            <p className="para_main mb-2">
              All refunds will be processed and reflect in your bank account
              within 14-22 business days of the date of cancellation and will be
              brought about using the method of payment agreed upon by the
              client at the beginning of the project.
            </p>
            <p className="para_main mb-2">
              <span>Note:</span> TekCorp Limited may at any time, without prior
              notice under its sole discretion, amend this refund policy. You
              are therefore requested to review this policy periodically. In any
              case of unmet deadlines and lack of performance, the customer is
              eligible for 100% refund once our Quality Assurance team has
              reviewed the case.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Refund;
