import React from "react";
import Hero from "../../Component/Hero/Hero";
import Reviews from "../../Component/Reviews/Reviews";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import InfoSec from "../../Component/InfoSec/InfoSec";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import { Helmet } from "react-helmet";

const SMMPage = () => {
  return (
    <>
      <Helmet>
        <title>Social Media Marketing Service - TekCorp</title>
        <meta
          name="keywords"
          content="Social Media Marketing Company in Pakistan"
        />
        <meta name="keywords" content="Social Media Marketing Company in UAE" />
        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/social-media-marketing"
        />
        <meta
          name="description"
          content="Our social media campaigns start with pinpointing and targeting the ideal audience for your business. Rest assured, our team of experts will meticulously manage your media channels, crafting a powerful social media presence tailored to your needs."
        />
      </Helmet>
      <Hero
        subHeading={"Engage, Grow, and Elevate Your Brand's Reach"}
        heading={"Boost Your Online Visibility with TekCorp"}
      />
      <InfoSec
        data={{
          highlightedWord: "Maximize Your",
          mainHeading: "Social Presence",
          para: `In today's digital landscape, TekCorp shines as a leading social media marketing firm, expertly representing clients across diverse industries such as technology, media, finance, and retail. Our strength lies in crafting strategies that not only spark conversations but also leave a lasting positive impact on your target audience.`,
          subHeading1: "Generate Results",
          subPara1: `We meticulously plan and execute strategies to consistently deliver outstanding and proven results.`,
          subHeading2: "Engaging Impression",
          subPara2: `At TekCorp, we ensure your company maintains a strong image and delivers engaging, high-value content that captivates your audience.`,
          subHeading3: "Customized Planning",
          subPara3: `To stand out on social media, every business needs a powerful strategy that delivers results. At TekCorp, we take on that responsibility with dedication and expertise, crafting the perfect plan to elevate your brand. Partner with us, and prepare to reach new heights of success.`,
        }}
      />
      <ImgByContent
        img={"/images/smm1.jpg"}
        services={true}
        span={"Attract your "}
        number={"01"}
        heading={"customers with effective social media campaigns"}
        text={
          "Understanding what motivates your customers is crucial. Targeting the right audience is often overlooked in social media marketing. How can you reach potential consumers if you don’t know who they are?"
        }
        text2={
          "Our social media campaigns start with pinpointing and targeting the ideal audience for your business. Rest assured, our team of experts will meticulously manage your media channels, crafting a powerful social media presence tailored to your needs."
        }
      />
      <ImgByContent
        img={"/images/smm2.jpg"}
        reverse={true}
        services={true}
        span={"Reach your "}
        number={"02"}
        heading={"targeted audience"}
        text={
          "Social media users seek engaging and relevant content that resonates with their interests. Our social media marketing team understands this need and works closely with you to enhance your business’s interaction with your audience through compelling content and active engagement."
        }
        text2={
          "We leverage the strengths of each social media platform to maximize your brand’s visibility. On Facebook, we tap into its extensive reach; on Instagram, we enhance visual appeal; on Twitter, we facilitate instant communication; and on LinkedIn, we build and strengthen B2B connections. Each platform is strategically utilized to make your brand stand out and thrive."
        }
      />
      <ImgByContent
        img={"/images/smm3.jpg"}
        services={true}
        span={"Expand your "}
        number={"03"}
        heading={"audience with compelling and visually captivating content"}
        text={
          "Building an online audience has become increasingly challenging due to the multitude of social media platforms, complex algorithms, and limited visibility. Many business owners find it difficult to dedicate time to effective social media engagement. Our social media management services are designed to help you maintain a strong presence and expand your network effortlessly."
        }
        text2={
          "We focus on showcasing your organization’s strengths, promptly addressing any direct messages—whether positive or negative—and fostering meaningful conversations to boost engagement and visibility. Let us handle your social media, so you can focus on growing your business."
        }
      />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default SMMPage;
